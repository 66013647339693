import airac from 'utils/airac';

const cache = {};

export default language => {
  if (!cache[language]) {
    cache[language] = {
      version: 8,
      name: 'SkyVector VFR Map',
      provider: 'SkyVector',
      sources: {
        'skyVector-vfr': {
          type: 'raster',
          tiles: [
            `${import.meta.env.VITE_SERENITY_TILE_PROXY}/skyvector/vfr/${airac}/{z}/{x}/{y}.jpg`
          ],
          maxzoom: 11,
          minzoom: 3,
          tileSize: 256
        }
      },
      layers: [{
        id: 'vfr',
        type: 'raster',
        source: 'skyVector-vfr'
      }],
      thumbnailUrl: '/img/base-layer-thumbnails/vfr-map-thumbnail.jpg',
      dark: false,
    };
  }

  return cache[language];
};
