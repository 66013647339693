import Smart from 'hocs/smart';
import { permissionsForCurrentUserAndOrg } from 'selectors/permissions';
import AssetQuery from './asset-query';

export default Smart(
  AssetQuery,
  state => ({
    organisationId: state.session.organisationId,
    previousColors: state.settings.map.previousColors,
    permissions: permissionsForCurrentUserAndOrg(state),
  }),
  ac => ({
    setPreviousColors: ac.settings.setPreviousColors,
    displaySnackbar: ac.app.displaySnackbar, // FIXME: DEV-3334
  })
);
