import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useReportsDataRepository, useHaveReportData } from 'repositories/reports/hooks';
import { getReportsLatest, getReportsHistoric } from 'apis/rest/reports/requests';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { setMostRecentDeviceReport } from 'actions/reports';
import useTimezone from 'hooks/session/useTimezone';
import { getSelectedDay } from 'slices/app.slice';

interface QueryReportsProps {
  day: string | null
  timezone: string
  organisationId: string
  setMostRecent: (timestamp: number) => void
}

export const QueryReports = ({ day, timezone, organisationId, setMostRecent }: QueryReportsProps): null => {
  const reportsRepo = useReportsDataRepository();
  const dateTime = useMemo(() => (day === null ? null : DateTime.fromISO(day, { zone: timezone })), [day, timezone]);

  const hasData = useHaveReportData();
  const gotCurrentReports = useRef<boolean>(false);
  const historicReportsTime = useRef<number | undefined>();

  // latest reports also works for reports older than a day
  const getLatestReports = useCallback(async () => {
    const latestReports = await getReportsLatest({ organisationId });
    latestReports.forEach(latestReport => reportsRepo.setAssetReports(latestReport.assetId, [latestReport], false));
    reportsRepo.updateAllAssets();
  }, [reportsRepo, organisationId]);

  useEffect(() => () => {
    gotCurrentReports.current = false;
    historicReportsTime.current = undefined;
    console.log('clear reports');
    reportsRepo.clear();
  }, [reportsRepo, organisationId, day, timezone]);

  useEffect(() => {
    // historic mode
    if (dateTime && historicReportsTime.current !== dateTime.startOf('day').toMillis()) {
      console.log(`getting historical reports for ${organisationId} from ${dateTime.startOf('day').toISO()} until ${dateTime.endOf('day').toISO()}`);
      if (hasData) {
        reportsRepo.clear();
        gotCurrentReports.current = false;
      }

      getReportsHistoric({ organisationId, from: dateTime.startOf('day').toMillis(), until: dateTime.endOf('day').toMillis() }).then(allReports => {
        const assetReports = allReports.reduce((reportsDict, report) => {
          reportsDict[report.assetId] = [...(reportsDict[report.assetId] || []), report];
          return reportsDict;
        }, {} as Record<number, Report[]>);

        Object.entries(assetReports).forEach(([asset, reportsForAsset]) => reportsRepo.setAssetReports(parseInt(asset, 10), reportsForAsset, false));
        reportsRepo.updateAllAssets();
      });
      historicReportsTime.current = dateTime.startOf('day').toMillis();
      return;
    }

    if (!gotCurrentReports.current && !dateTime) {
      if (hasData) {
        reportsRepo.clear();
        historicReportsTime.current = undefined;
      }

      const currentDateTime = DateTime.now().setZone(timezone);
      getLatestReports()
        // .then(() => new Promise<void>((res, rej) => (performanceMode ? rej() : res())))
        .then(() => {
          console.log(`getting reports for ${organisationId} on current day from ${currentDateTime.startOf('day').toISO()} until ${currentDateTime.toISO()}`);
          return getReportsHistoric({ organisationId, from: currentDateTime.startOf('day').toMillis(), until: currentDateTime.toMillis() });
        })
        .then(allReports => {
          const assetReports = allReports.reduce((reportsDict, report) => {
            reportsDict[report.assetId] = [...(reportsDict[report.assetId] || []), report];
            return reportsDict;
          }, {} as Record<number, Report[]>);

          const count = reportsRepo.reportRepository.reports.size;
          Object.entries(assetReports).forEach(([asset, reportsForAsset]) => {
            const assetId = parseInt(asset, 10);
            const latestReport = reportsRepo.reportRepository.getLatestReport(assetId);
            if (!reportsForAsset.some(r => r.id === latestReport?.id) && latestReport) {
              reportsForAsset.push(latestReport);
            }
            reportsRepo.setAssetReports(assetId, reportsForAsset, false);
          });

          console.log(`inserted ${reportsRepo.reportRepository.reports.size - count} reports for ${organisationId}`);

          reportsRepo.updateAllAssets();
          const latestReportTimestamp = allReports.length ? Math.max(...allReports.map(r => r.received)) : undefined;
          if (latestReportTimestamp) {
            setMostRecent(latestReportTimestamp * 1000);
          }
        });
      gotCurrentReports.current = true;
    }
  }, [reportsRepo, dateTime, getLatestReports, organisationId, hasData, setMostRecent, timezone]);

  return null;
};

const QueryReportsDummy = () => {
  const dispatch = useDispatch();
  const day = useSelector(getSelectedDay);
  const timezone = useTimezone();
  const organisationId = useOrganisationId();
  const setMostRecent = useCallback((ts: number) => setMostRecentDeviceReport(ts)(dispatch), [dispatch]);

  return (
    <QueryReports
      day={day}
      timezone={timezone}
      organisationId={organisationId}
      setMostRecent={setMostRecent}
    />
  );
};

export default QueryReportsDummy;
