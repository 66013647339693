import Smart from 'hocs/smart';
import OmniboxResults from './results-view';

export default Smart(
  OmniboxResults,
  state => ({
    organisationId: state.session.organisationId,
    selectedMapId: state.map.selectedMapId,
    // TODO: use react-query to re-implement assetState (in distress / of concern / airborne etc)
    // assetState: state.assetState,
  }),
  ac => ({
    assignItemToMap: ac.map.assignItemToMap,
    fetchDistressIncident: ac.asset.fetchDistressIncident,
  })
);
