import { Options as CSVOptions, Input } from 'csv-stringify';
import useVolume, { useVolumePerPeriod } from 'hooks/units/useVolume';
import sumBy from 'lodash/fp/sumBy';
import { DateTime, Duration } from 'luxon';
import { useSelector } from 'react-redux';
import { selectAssetsNoDrops } from 'slices/statsFilter.slice';
import { useTranslations } from 'use-intl';
import useDistance from 'hooks/units/useDistance';
import { distance, speed } from 'helpers/unitsOfMeasure';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import { TripBasic } from 'apis/rest/trips/types';
import { DropGroup } from 'apis/rest/firefighting/types';

export interface EfficiencyStats {
  dropsPerHour: number;
  volumePerHour: number;
  dropsPerFlightHour: number;
  volumePerFlightHour: number;
}

type PartialStats = {
  [key in keyof EfficiencyStats]?: number;
};

type StatsAccumulator = {
  [key in keyof EfficiencyStats]: { sum: number, count: number };
};

const calculateEfficiencyStatsForTrip = (trip: TripBasic, dropGroups: DropGroup[]): PartialStats | null => {
  const stats: PartialStats = {};

  if (dropGroups.length === 0) {
    return null;
  }

  // start time is either the first fill if it's contained within the trip, or the first drop otherwise
  const startTime = Math.min(...dropGroups.map(dg => (dg.startTimeMs < trip.startTimeMs ? dg.drops[0].startTimeMs : dg.startTimeMs)));
  const hours = (Math.max(...dropGroups.map(dg => dg.endTimeMs)) - startTime) / 3.6e6;

  const count = dropGroups.length;
  const volume = sumBy('volumeDroppedLitres', dropGroups);

  if (startTime !== undefined && hours > 0 && count > 1) {
    stats.dropsPerHour = count / hours;
    stats.volumePerHour = volume / hours;
  }

  const flightHours = (trip.endTimeMs - trip.startTimeMs) / 3.6e6;
  if (flightHours > 0) {
    stats.dropsPerFlightHour = count / flightHours;
    stats.volumePerFlightHour = volume / flightHours;
  }

  return stats;
};

const isWithinTrip = (t: TripBasic) => (dg: DropGroup) => {
  if (dg.assetId !== t.assetId) { return false; }

  const startDrop = dg.drops.at(0)?.startTimeMs;
  const endDrop = dg.drops.at(-1)?.endTimeMs;

  if (!startDrop || !endDrop) { return false; }
  return startDrop >= t.startTimeMs && endDrop <= t.endTimeMs;
};

export const calculateEfficiencyStats = (trips: TripBasic[], dropGroups: DropGroup[], filter?: (drop: DropGroup) => boolean): EfficiencyStats => {
  const filteredDropGroups = dropGroups.filter(filter ?? (() => true));
  const stats: PartialStats[] = trips
    .map(t => calculateEfficiencyStatsForTrip(t, filteredDropGroups.filter(isWithinTrip(t))))
    .filter((s): s is PartialStats => s !== null);

  const statsAcc = stats.reduce<StatsAccumulator>((acc, stat) => Object.entries(stat)
    .reduce((innerAcc, [k, v]) => {
      const key = k as keyof EfficiencyStats;
      innerAcc[key] = {
        sum: acc[key].sum + v,
        count: acc[key].count + 1,
      };
      return innerAcc;
    }, acc), {
    dropsPerHour: {
      sum: 0,
      count: 0,
    },
    volumePerHour: {
      sum: 0,
      count: 0,
    },
    dropsPerFlightHour: {
      sum: 0,
      count: 0,
    },
    volumePerFlightHour: {
      sum: 0,
      count: 0,
    },
  });

  return Object.keys(statsAcc)
    .reduce<EfficiencyStats>((acc, k) => {
      const key = k as keyof EfficiencyStats;
      acc[key] = statsAcc[key].count > 0 ? statsAcc[key].sum / statsAcc[key].count : 0;
      return acc;
    }, {
      dropsPerHour: 0,
      volumePerHour: 0,
      dropsPerFlightHour: 0,
      volumePerFlightHour: 0,
    });
};

export const getDropGroupsOutsideOfTrips = (trips: TripBasic[], dropGroups: DropGroup[]): string[] => {
  const tripChecks = trips.map(t => isWithinTrip(t));
  return dropGroups.filter(dg => tripChecks.every(tc => !tc(dg))).map(dg => dg.id);
};

// Total flight time in milliseconds
export const calculateTotalFlightTime = (trips: TripBasic[], dropGroups: DropGroup[]) => trips
  .filter(t => dropGroups.some(isWithinTrip(t))) // trips where some drop groups can be found in it
  .reduce<number>((acc, trip) => acc + (trip.endTimeMs - trip.startTimeMs), 0);

export const useCSVFirefightingFormatter = (): {
  createCSVSummaryData: (assets: AssetWithDevice[], selectedDeviceIds: number[], drops: DropGroup[], trips: TripBasic[], visibleSuppressants: Record<Suppressant, boolean>) => [Input, CSVOptions],
  createCSVAssetData: (dropGroups: DropGroup[], timezone: string, visibleSuppressants: Record<Suppressant, boolean>) => Promise<[Input, CSVOptions]>
} => {
  const units = useUnitSettings();
  const volume = useVolume();
  const volumePerPeriod = useVolumePerPeriod();
  const distanceUnits = useDistance();
  const speedUnits = units.speedAir;
  const shortDistanceUnits = distanceUnits.unit === 'kilometres' ? 'metres' : 'feet';
  const shortDistanceUnitsLabel = distance.label(shortDistanceUnits);
  const assetNoDropsEnabled = useSelector(selectAssetsNoDrops);
  const t = useTranslations('pages.reporting.firefighting');
  const assetsT = useTranslations('pages.assets');

  const createCSVSummaryData = (assets: AssetWithDevice[], selectedDeviceIds: number[], drops: DropGroup[], trips: TripBasic[], visibleSuppressants: Record<Suppressant, boolean>): [Input, CSVOptions] => {
    const selectedAssets = assets.filter(a => selectedDeviceIds.includes(a.deviceId) && (!assetNoDropsEnabled || drops.some(d => d.assetId === a.id && visibleSuppressants[d.suppressant])));
    const records: Input = selectedAssets.map(asset => {
      const relevantDrops = drops.filter(d => d.assetId === asset.id && visibleSuppressants[d.suppressant]);
      const relevantTrips = trips.filter(trip => trip.assetId === asset.id);
      const stats = calculateEfficiencyStats(relevantTrips, drops);
      const totalFlightTime = calculateTotalFlightTime(relevantTrips, drops);

      const sumVolume = sumBy<DropGroup>('volumeDroppedLitres');

      const relevantFreshWaterDrops = relevantDrops.filter(d => d.suppressant === 'FreshWater');
      const freshWaterVolume = volume.create(sumVolume(relevantFreshWaterDrops)).unitValue.toFixed(0);
      const relevantSaltWaterDrops = relevantDrops.filter(d => d.suppressant === 'SaltWater');
      const saltWaterVolume = volume.create(sumVolume(relevantSaltWaterDrops)).unitValue.toFixed(0);
      const relevantRetardantDrops = relevantDrops.filter(d => d.suppressant === 'Retardant');
      const retardantVolume = volume.create(sumVolume(relevantRetardantDrops)).unitValue.toFixed(0);
      const relevantFoamDrops = relevantDrops.filter(d => d.suppressant === 'Foam');
      const foamVolume = volume.create(sumVolume(relevantFoamDrops)).unitValue.toFixed(0);
      const relevantUnknownSuppressantDrops = relevantDrops.filter(d => d.suppressant === 'Unknown');
      const unknownSuppressantVolume = volume.create(sumVolume(relevantUnknownSuppressantDrops)).unitValue.toFixed(0);

      return {
        organisation: asset.ownerName,
        name: asset.name,
        tailNumber: asset.tailNumber,
        callsign: asset.callSign,
        category: asset.category,
        makeModelVariant: [asset.make, asset.model, asset.variant].filter(x => x).join(' '),
        totalDrops: relevantDrops.length,
        totalVolume: volume.create(sumVolume(relevantDrops)).unitValue.toFixed(0),
        totalFlightTime: Duration.fromMillis(totalFlightTime).as('hours').toFixed(2),
        averageDropsPerHour: stats.dropsPerHour.toFixed(2),
        averageVolumePerHour: volumePerPeriod.create(stats.volumePerHour, 'h').unitValue.toFixed(2),
        averageDropsPerFlightHour: stats.dropsPerFlightHour.toFixed(2),
        averageVolumePerFlightHour: volumePerPeriod.create(stats.volumePerFlightHour, 'h').unitValue.toFixed(2),
        freshWaterDrops: relevantFreshWaterDrops.length,
        freshWaterVolume,
        saltWaterDrops: relevantSaltWaterDrops.length,
        saltWaterVolume,
        retardantDrops: relevantRetardantDrops.length,
        retardantVolume,
        foamDrops: relevantFoamDrops.length,
        foamVolume,
        unknownSuppressantDrops: relevantUnknownSuppressantDrops.length,
        unknownSuppressantVolume
      };
    });

    let baseColumns = [
      { key: 'organisation', header: assetsT('assetsTable.owner') },
      { key: 'name', header: assetsT('assetsTable.name') },
      { key: 'tailNumber', header: assetsT('assetsTable.tailNumber') },
      { key: 'callsign', header: assetsT('assetsTable.callSign') },
      { key: 'category', header: assetsT('assetsTable.category') },
      { key: 'makeModelVariant', header: assetsT('assetsTable.makeModelVariant') },
      { key: 'totalDrops', header: t('csv.summary.dropCount') },
      { key: 'totalVolume', header: t('csv.summary.totalVolume', { unit: volume.unitLabel }) },
      { key: 'totalFlightTime', header: t('csv.summary.totalFlightTimeHrs') },
      { key: 'averageDropsPerHour', header: t('csv.summary.averageDropsPerHour') },
      { key: 'averageVolumePerHour', header: t('csv.summary.averageVolumePerHour', { unit: volumePerPeriod.unitLabel }) },
      { key: 'averageDropsPerFlightHour', header: t('csv.summary.averageDropsPerFlightHour') },
      { key: 'averageVolumePerFlightHour', header: t('csv.summary.averageVolumePerFlightHour', { unit: volumePerPeriod.unitLabel }) },
    ];

    if (visibleSuppressants.FreshWater && records.some(r => r.freshWaterDrops > 0)) {
      baseColumns = [...baseColumns,
        { key: 'freshWaterDrops', header: `${t('suppressant.FreshWater')} ${t('csv.summary.dropCount')}` },
        { key: 'freshWaterVolume', header: `${t('suppressant.FreshWater')} ${t('csv.summary.totalVolume', { unit: volume.unitLabel })}` },
      ];
    }

    if (visibleSuppressants.SaltWater && records.some(r => r.saltWaterDrops > 0)) {
      baseColumns = [...baseColumns,
        { key: 'saltWaterDrops', header: `${t('suppressant.SaltWater')} ${t('csv.summary.dropCount')}` },
        { key: 'saltWaterVolume', header: `${t('suppressant.SaltWater')} ${t('csv.summary.totalVolume', { unit: volume.unitLabel })}` },
      ];
    }

    if (visibleSuppressants.Retardant && records.some(r => r.retardantDrops > 0)) {
      baseColumns = [...baseColumns,
        { key: 'retardantDrops', header: `${t('suppressant.Retardant')} ${t('csv.summary.dropCount')}` },
        { key: 'retardantVolume', header: `${t('suppressant.Retardant')} ${t('csv.summary.totalVolume', { unit: volume.unitLabel })}` },
      ];
    }

    if (visibleSuppressants.Foam && records.some(r => r.foamDrops > 0)) {
      baseColumns = [...baseColumns,
        { key: 'foamDrops', header: `${t('suppressant.Foam')} ${t('csv.summary.dropCount')}` },
        { key: 'foamVolume', header: `${t('suppressant.Foam')} ${t('csv.summary.totalVolume', { unit: volume.unitLabel })}` },
      ];
    }

    if (visibleSuppressants.Unknown && records.some(r => r.unknownSuppressantDrops > 0)) {
      baseColumns = [...baseColumns,
        { key: 'unknownSuppressantDrops', header: `${t('suppressant.Unknown')} ${t('csv.summary.dropCount')}` },
        { key: 'unknownSuppressantVolume', header: `${t('suppressant.Unknown')} ${t('csv.summary.totalVolume', { unit: volume.unitLabel })}` },
      ];
    }

    const headers: CSVOptions = {
      header: true,
      columns: baseColumns
    };

    return [records, headers];
  };

  const createCSVAssetData = async (dropGroups: DropGroup[], timezone: string, visibleSuppressants: Record<Suppressant, boolean>): Promise<[Input, CSVOptions]> => {
    const records: Input = dropGroups.filter(dg => visibleSuppressants[dg.suppressant]).map(dropGroup => {
      const totalDropDurationMs = sumBy('durationMs', dropGroup.drops);
      const averageDropRate = dropGroup.volumeDroppedLitres / (totalDropDurationMs / 1000);

      return {
        fillDate: dropGroup.fills.length > 0 ? DateTime.fromMillis(dropGroup.startTimeMs)
          .setZone(timezone)
          .toFormat('dd MMM yyyy, HH:mm:ss ZZZ') : '—',
        fillLocation: dropGroup.locations.maxFill?.placeName ?? t('unknownLocation'),
        fillCoords: dropGroup.locations.maxFill ? `${dropGroup.locations.maxFill.coords[1].toFixed(3)}, ${dropGroup.locations.maxFill.coords[0].toFixed(3)}` : '—',
        distanceToDrop: dropGroup.flight?.distanceKm ? distance.fromSI(dropGroup.flight.distanceKm * 1000, distanceUnits.unit).toFixed(2) : '—',
        flightDuration: dropGroup.flight?.durationMs ? Duration.fromMillis(dropGroup.flight.durationMs).as('seconds').toFixed(0) : '—',
        dropDate: DateTime.fromMillis(dropGroup.drops[0].startTimeMs)
          .setZone(timezone)
          .toFormat('dd MMM yyyy, HH:mm:ss ZZZ'),
        dropLocation: dropGroup.locations.averageDrop.placeName,
        dropCoords: `${dropGroup.locations.averageDrop.coords[1].toFixed(3)}, ${dropGroup.locations.averageDrop.coords[0].toFixed(3)}`,
        dropSuppressant: t(`suppressant.${dropGroup.suppressant}`),
        dropVolume: volume.create(dropGroup.volumeDroppedLitres).unitValue.toFixed(0),
        dropDuration: Duration.fromMillis(totalDropDurationMs).as('seconds').toFixed(0),
        dropRate: volumePerPeriod.create(averageDropRate, 's').unitValue.toFixed(2),
        dropSpeed: speed.fromKmh(dropGroup.drops[0].speedKph, speedUnits).toFixed(0),
        dropDistance: distance.fromSI(dropGroup.drops[0].distanceKm * 1000, shortDistanceUnits).toFixed(2),
      };
    });

    const headers: CSVOptions = {
      header: true,
      columns: [
        { key: 'fillDate', header: t('detailSummary.fillDate') },
        { key: 'fillLocation', header: t('detailSummary.fillLocation') },
        { key: 'fillCoords', header: t('csv.asset.fillCoords') },
        { key: 'distanceToDrop', header: t('csv.asset.directDistance', { unit: distanceUnits.unitLabel }) },
        { key: 'flightDuration', header: t('csv.asset.flightDuration') },
        { key: 'dropDate', header: t('detailSummary.dropDate') },
        { key: 'dropLocation', header: t('detailSummary.dropLocation') },
        { key: 'dropCoords', header: t('csv.asset.dropCoords') },
        { key: 'dropSuppressant', header: t('csv.asset.suppressant') },
        { key: 'dropVolume', header: t('csv.asset.volume', { unit: volume.unitLabel }) },
        { key: 'dropDuration', header: t('csv.asset.duration') },
        { key: 'dropRate', header: t('csv.asset.rate', { unit: volumePerPeriod.unitLabel }) },
        { key: 'dropSpeed', header: t('csv.asset.speed', { unit: speedUnits }) },
        { key: 'dropDistance', header: t('csv.asset.distance', { unit: shortDistanceUnitsLabel }) }
      ],
    };

    return [records, headers];
  };

  return { createCSVSummaryData, createCSVAssetData };
};
