import useFeatureAssets, { FeatureAssetsResult } from 'contexts/featureAssets/useFeatureAssets';
import { useCallback, useMemo } from 'react';

export enum AssetsGeofenceStatus {
  All,
  Some,
  None,
  Never,
  Loading,
}

export const getGeofenceStatus = (
  featureAssets: FeatureAssetsResult,
  assetGroupIds: number[] | undefined
): AssetsGeofenceStatus => {
  if (featureAssets.isLoading) return AssetsGeofenceStatus.Loading;
  if (featureAssets.all) return AssetsGeofenceStatus.All;
  if (!featureAssets.some) return AssetsGeofenceStatus.Never;
  const all = assetGroupIds?.every(id => featureAssets.completeAssetGroups.includes(id));
  if (all) return AssetsGeofenceStatus.All;

  const someOfGivenGroups = assetGroupIds?.some(id => (
    featureAssets.completeAssetGroups.includes(id)
    || featureAssets.partialAssetGroups.includes(id)
  )) ?? false;
  return someOfGivenGroups ? AssetsGeofenceStatus.Some : AssetsGeofenceStatus.None;
};

export const useAssetsGeofenceStatus = (assetGroupIds: number[] | undefined) => {
  const geofencesFeatureAssets = useFeatureAssets('manage.geofencing');

  return useMemo<AssetsGeofenceStatus>(() => (
    getGeofenceStatus(geofencesFeatureAssets, assetGroupIds)
  ), [assetGroupIds, geofencesFeatureAssets]);
};

export const useAssetsNotificationsStatus = () => {
  const featureAssets = useFeatureAssets('manage.notifications');

  return useCallback((assets: AssetBasic[]) => {
    if (featureAssets.isLoading) return { loading: true, enabled: [], disabled: [] };
    if (featureAssets.all) return { loading: false, enabled: assets, disabled: [] };
    if (!featureAssets.some) return { loading: false, enabled: [], disabled: assets };

    const enabled = assets.filter(a => featureAssets.assetIds.includes(a.id));
    if (enabled.length === assets.length) return { loading: false, enabled, disabled: [] };
    return { loading: false, enabled, disabled: assets.filter(a => !enabled.includes(a)) };
  }, [featureAssets]);
};