import Smart from 'hocs/smart';
import TripReports from './tripReports-view';

export default Smart(
  TripReports,
  state => ({}),
  ac => ({
    setAssetLegs: ac.legs.setAssetLegs,
    displaySnackbar: ac.app.displaySnackbar, // FIXME: DEV-3334
    updateSetting: ac.settings.updateSetting,
  })
);
