import Smart from 'hocs/smart';
import NewConversationBar from './newConversationBar-view';

export default Smart(
  NewConversationBar,
  state => ({}),
  ac => ({
    displaySnackbar: ac.app.displaySnackbar, // FIXME: DEV-3334
  })
);
