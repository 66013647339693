import Smart from 'hocs/smart';
import MapView from './map-view';

export default Smart(
  MapView,
  state => ({
    organisationId: state.session.organisationId,
    contextboxOpen: state.ui.contextboxOpen,
    selectedItem: state.app.selectedItem,
  }),
  ac => ({
    setTextFilter: ac.app.setTextFilter, // FIXME: DEV-3334
    displaySnackbar: ac.app.displaySnackbar, // FIXME: DEV-3334
    assignItemToMap: ac.map.assignItemToMap,
  })
);
