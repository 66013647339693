import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ReactMapGl, {
  MapProvider,
  MapRef,
  Source,
  useControl,
  useMap,
  AttributionControl,
  NavigationControl,
} from 'react-map-gl';
import { MapSourceDataEvent } from 'mapbox-gl';
import useMapTemplate from 'hooks/settings/map/useMapConfig';
import { Box } from '@mui/material';
import { ArcLayer, PathLayer, ScatterplotLayer } from '@deck.gl/layers';
import type { DropGroup } from 'apis/rest/firefighting/types';
import { hexToRGBArray } from 'helpers/color';
import { safeBounds } from 'helpers/geo';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox';
import { points } from '@turf/helpers';
import { PickingInfo } from '@deck.gl/core';
import { useSuppressantColors } from './helpers';

const mapboxToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

const DeckGLOverlay = (props: MapboxOverlayProps) => {
  // @ts-ignore
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

interface FirefightingMapProps {
  selectedDropGroup?: string
  hoveredDropGroup?: string
  setHoveredDropGroup: React.Dispatch<React.SetStateAction<string | undefined>>
  dropGroups: DropGroup[],
  leftPadding?: number
}

interface DropArc {
  id: string;
  from: [number, number, number];
  to: [number, number, number];
  color: ColorAlpha;
  dropVol: number;
}

interface DropLine {
  path: [number, number][];
  color: string;
}

const addTerrain = (coords: [number, number, number] | [number, number], map?: MapRef) => {
  const res = map?.queryTerrainElevation([coords[0], coords[1]]) ?? 0;
  return [coords[0], coords[1], res] as [number, number, number];
};

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

const FirefightingMap: React.FC<FirefightingMapProps> = ({
  selectedDropGroup,
  hoveredDropGroup,
  setHoveredDropGroup,
  dropGroups,
  leftPadding = 300
}) => {
  const mapTemplate = useMapTemplate();
  const suppressantColor = useSuppressantColors();
  const { default: map } = useMap();
  const [shouldRecalculateTerrain, setShouldRecalculateTerrain] = useState(false);

  const dropArcs: DropArc[] = useMemo(() => dropGroups.flatMap(dg => {
    if (!dg.locations.maxFill) {
      return [];
    }
    const _ = shouldRecalculateTerrain;
    return [{
      id: dg.id,
      from: addTerrain(dg.locations.maxFill.coords, map),
      to: addTerrain(dg.locations.averageDrop.coords, map),
      color: hexToRGBArray(suppressantColor[dg.suppressant]),
      dropVol: dg.volumeDroppedLitres,
    }];
  }), [dropGroups, shouldRecalculateTerrain, map, suppressantColor]);

  const dropLines: DropLine[] = useMemo(() => dropGroups.flatMap(dg => dg.drops.map(d => ({ path: [d.start, d.end], color: suppressantColor[dg.suppressant] }))), [dropGroups, suppressantColor]);

  useEffect(() => {
    const paddingAmount = 100;
    const padding = { top: paddingAmount, bottom: paddingAmount, left: paddingAmount + leftPadding, right: paddingAmount };
    if (selectedDropGroup) {
      const dg = dropGroups.find(d => d.id === selectedDropGroup);
      if (dg) {
        map?.fitBounds(safeBounds(points(
          [...dg.fills, ...dg.drops].flatMap(f => [f.start, f.end])
        )), { padding });
      }
    } else if (dropGroups.length > 0) {
      map?.fitBounds(safeBounds(
        points(dropGroups.flatMap(
          dg => [...dg.fills, ...dg.drops].flatMap(f => [f.start, f.end])
        ))
      ), { padding });
    }
  }, [dropGroups, selectedDropGroup, map, leftPadding]);

  const arcLayer = useMemo(() => new ArcLayer<DropArc>({
    id: 'drop-arcs',
    data: dropArcs,
    getWidth: d => d.dropVol / 200,
    widthUnits: 'meters',
    widthMaxPixels: 4,
    widthMinPixels: 1,
    getHeight: 0.5,
    getSourcePosition: d => d.from,
    getTargetPosition: d => d.to,
    getSourceColor: d => [...d.color.slice(0, 3), d.id === hoveredDropGroup ? 255 : 80] as ColorAlpha,
    getTargetColor: d => [255, 0, 0, d.id === hoveredDropGroup ? 255 : 20],
    opacity: 1,
    pickable: true,
    updateTriggers: {
      getSourceColor: [hoveredDropGroup],
      getTargetColor: [hoveredDropGroup],
    },
  }), [dropArcs, hoveredDropGroup]);

  const fillLayers = useMemo(() => [
    new ScatterplotLayer<DropArc>({
      id: 'fill-dots-bg',
      data: dropArcs,
      getPosition: d => d.from,
      radiusUnits: 'meters',
      radiusMinPixels: 5,
      lineWidthUnits: 'meters',
      getRadius: 20,
      getLineWidth: 10,
      stroked: true,
      filled: false,
      getLineColor: [255, 255, 255],
    }),
    new ScatterplotLayer<DropArc>({
      id: 'fill-dots',
      data: dropArcs,
      getPosition: d => d.from,
      radiusUnits: 'meters',
      lineWidthUnits: 'meters',
      radiusMinPixels: 4,
      getRadius: 20,
      getLineWidth: 5,
      stroked: true,
      filled: false,
      getLineColor: d => d.color,
    }),
    new ScatterplotLayer<DropArc>({
      id: 'hovered-fill-dots',
      data: dropArcs.filter(d => d.id === hoveredDropGroup),
      getPosition: d => d.from,
      radiusUnits: 'meters',
      radiusMinPixels: 2,
      getRadius: 8,
      getFillColor: d => d.color,
    }),
  ], [dropArcs, hoveredDropGroup]);

  const dropLineLayer = useMemo(() => {
    const data = dropLines.map(dl => ({ ...dl, path: [addTerrain(dl.path[0], map), addTerrain(dl.path[1], map)] }));
    const dotData = data.flatMap(dl => [{ ...dl, path: undefined, position: dl.path[0] }, { ...dl, path: undefined, position: dl.path[1] }]);
    const _ = shouldRecalculateTerrain;
    return [
      new PathLayer({
        id: 'drop-lines-bg',
        data,
        widthUnits: 'meters',
        widthMinPixels: 4,
        getWidth: 20,
        getColor: [255, 255, 255],
        capRounded: true,
      }),
      new ScatterplotLayer<ArrayElement<typeof dotData>>({
        id: 'drop-dots-bg',
        data: dotData,
        radiusUnits: 'meters',
        radiusMinPixels: 5,
        getRadius: 25,
        getFillColor: [255, 255, 255],
      }),
      new ScatterplotLayer<ArrayElement<typeof dotData>>({
        id: 'drop-dots',
        data: dotData,
        radiusUnits: 'meters',
        radiusMinPixels: 4,
        getRadius: 20,
        getFillColor: d => hexToRGBArray(d.color),
      }),
      new PathLayer({
        id: 'drop-lines',
        data,
        widthUnits: 'meters',
        widthMinPixels: 2,
        getWidth: 10,
        getColor: d => hexToRGBArray(d.color),
        capRounded: true,
      }),
    ];
  }, [dropLines, shouldRecalculateTerrain, map]);

  const onSourceData = useCallback((e: MapSourceDataEvent) => {
    if (e.sourceId !== 'mapbox-dem') {
      return;
    }
    setShouldRecalculateTerrain(old => !old);
  }, [setShouldRecalculateTerrain]);

  const onDeckHover = useCallback((i: PickingInfo) => {
    if (!i.picked || i.layer?.id !== 'drop-arcs') {
      if (hoveredDropGroup) {
        setHoveredDropGroup(undefined);
      }
      return;
    }
    setHoveredDropGroup(i.object.id);
  }, [hoveredDropGroup, setHoveredDropGroup]);

  return (
    <Box position="absolute" width="100%" height="100%">
      <ReactMapGl
        reuseMaps
        mapStyle={mapTemplate.template}
        mapboxAccessToken={mapboxToken}
        onSourceData={onSourceData}
        projection={{ name: 'mercator' }}
        attributionControl={mapTemplate.provider === 'Mapbox'}
        terrain={{ source: 'mapbox-dem', exaggeration: 1 }}
      >
        <Source
          id="mapbox-dem"
          type="raster-dem"
          url="mapbox://mapbox.mapbox-terrain-dem-v1"
          tileSize={512}
          maxzoom={14}
         />
        {mapTemplate.provider !== 'Mapbox' && <AttributionControl style={{ zIndex: 1 }} customAttribution={`Maps provided by ${mapTemplate.provider}`} />}
        <DeckGLOverlay
          onHover={onDeckHover}
          layers={[...dropLineLayer, ...fillLayers, arcLayer]}
          pickingRadius={12}
          // @ts-ignore
          parameters={{ depthTest: false }}
          interleaved
        />
        <NavigationControl style={{ zIndex: 1 }} visualizePitch />
      </ReactMapGl>
    </Box>
  );
};

export default (props: FirefightingMapProps) => <MapProvider><FirefightingMap {...props} /></MapProvider>;
