import React from 'react';
import {
  IconButton,
  Tooltip,
  Typography,
  List,
  ListItemText,
  ListItemIcon,
  Stack,
  ListItem,
  Switch, FormControlLabel
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslations } from 'use-intl';
import useIsUsercodeLogin from 'hooks/session/useIsUsercodeLogin';
import { useTheme } from '@mui/material/styles';
import ToggleablePanel from 'components/shared/toggleablePanel';
import StaffIndicator from 'components/shared/staffIndicator';
import { logout } from 'apis/auth';
import { useIntercom } from 'react-use-intercom';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import { useAppDispatch } from 'store/types';
import { useSelector } from 'react-redux';
import {
  selectIsStaffMember,
  selectDemoMode,
  toggleDemoMode
} from 'slices/session/session.slice';
import { AccountWrapper, IconSvg, OffsetWrapper, PocketListItemButton, StyledLink } from './userPocket-styles';

const HeaderUserPocket = ({ user }) => {
  const t = useTranslations('header.modules.userPocket');
  const usercodeLogin = useIsUsercodeLogin();
  const theme = useTheme();
  const { shutdown } = useIntercom();
  const dispatch = useAppDispatch();

  const isStaffMember = useSelector(selectIsStaffMember);
  const isDemoMode = useSelector(selectDemoMode);

  const doLogout = () => {
    shutdown();
    logout();
  };

  const handleToggleDemoMode = () => dispatch(toggleDemoMode(!isDemoMode));

  const header = (
    <Tooltip title={t(usercodeLogin ? 'orgSettingsButtonText' : 'accountSettingsButtonText')} placement="bottom">
      <IconButton size="large" sx={{ color: 'common.whiteConstant' }}>
        <AccountCircleIcon fontSize="1.5rem" />
      </IconButton>
    </Tooltip>
  );


  return (
    <ToggleablePanel header={header}>
      <AccountWrapper>
        <OffsetWrapper>
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <circle fillRule="evenodd" clipRule="evenodd" fill={theme.palette.common.lightGrey} cx="24" cy="24" r="23" />
            <path fillRule="evenodd" clipRule="evenodd" fill={theme.palette.common.darkBlueConstant} d="M24,16c-3.3077106,0-6,2.6914291-6,6s2.6922894,6,6,6 s6-2.6914291,6-6S27.3077106,16,24,16" />
            <path fillRule="evenodd" clipRule="evenodd" fill={theme.palette.common.darkBlueConstant} d="M27.9992676,30h-7.9985352 C16.6865234,30,14,32.6780396,14,36.0032959v8.7140503C17.0238037,46.1795654,20.4160156,47,24,47s6.9761963-0.8204346,10-2.2826538 v-8.7140503C34,32.6877441,31.3234863,30,27.9992676,30z" />
          </svg>
          <Typography variant="h3" gutterBottom mt={2} fontSize="1.2rem">
            {user.name}
            <StaffIndicator user={user} />
          </Typography>
          <Typography variant="body1" gutterBottom>{user.email}</Typography>
        </OffsetWrapper>
        <List sx={{ width: '100%', m: 0, p: 0 }}>
          {!usercodeLogin && (
            <StyledLink to="/settings/account">
              <PocketListItemButton>
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <IconSvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                      <circle fill="none" stroke={theme.palette.common.darkBlueConstant} cx="12" cy="12" r="3" strokeMiterlimit="10" />
                      <path fill="none" stroke={theme.palette.common.darkBlueConstant} d="M20,12a8.049,8.049,0,0,0-.188-1.713l2.714-2.055-2-3.464L17.383,6.094a7.987,7.987,0,0,0-2.961-1.719L14,1H10L9.578,4.375A7.987,7.987,0,0,0,6.617,6.094L3.474,4.768l-2,3.464,2.714,2.055a7.9,7.9,0,0,0,0,3.426L1.474,15.768l2,3.464,3.143-1.326a7.987,7.987,0,0,0,2.961,1.719L10,23h4l.422-3.375a7.987,7.987,0,0,0,2.961-1.719l3.143,1.326,2-3.464-2.714-2.055A8.049,8.049,0,0,0,20,12Z" strokeMiterlimit="10" />
                    </g>
                  </IconSvg>
                </ListItemIcon>
                <ListItemText
                  primary={t('accountSettingsButtonText')}
                />
              </PocketListItemButton>
            </StyledLink>
          )}
          {!usercodeLogin && (
            <StyledLink to="/settings/organisation">
              <PocketListItemButton>
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <IconSvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path d="M8,6L8,6C6.3,6,5,4.7,5,3v0c0-1.7,1.3-3,3-3h0c1.7,0,3,1.3,3,3v0C11,4.7,9.7,6,8,6z" />
                    <polygon points="10,16 6,16 6,12 4,10 4,7 12,7 12,10 10,12 " />
                    <path data-color="color-2" d="M2,5L2,5C0.9,5,0,4.1,0,3v0c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v0C4,4.1,3.1,5,2,5z" />
                    <polygon data-color="color-2" points="3,10.4 3,6 0,6 0,9 1,10 1,14 4,14 4,11.4 " />
                    <path data-color="color-2" d="M14,5L14,5c1.1,0,2-0.9,2-2v0c0-1.1-0.9-2-2-2h0c-1.1,0-2,0.9-2,2v0C12,4.1,12.9,5,14,5z" />
                    <polygon data-color="color-2" points="13,10.4 13,6 16,6 16,9 15,10 15,14 12,14 12,11.4 " />
                  </IconSvg>
                </ListItemIcon>
                <ListItemText
                  primary={t('orgSettingsButtonText')}
                />
              </PocketListItemButton>
            </StyledLink>
          )}
          {isStaffMember
            && (
              <ListItem>
                <ListItemIcon sx={{ minWidth: '35px' }}>
                  <Stack direction="row" alignItems="center">
                    <FormControlLabel control={<Switch
                      onChange={handleToggleDemoMode}
                      checked={isDemoMode}
                    />} label="Demo Mode" />
                  </Stack>
                </ListItemIcon>
              </ListItem>
            )}
          <PocketListItemButton onClick={doLogout}>
            <ListItemIcon sx={{ minWidth: '35px' }}>
              <IconSvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                  <line fill="none" stroke={theme.palette.common.darkBlueConstant} strokeMiterlimit="10" x1="17" y1="12" x2="2" y2="12" />
                  <polyline fill="none" stroke={theme.palette.common.darkBlueConstant} strokeMiterlimit="10" points=" 7,17 2,12 7,7 " />
                  <polyline fill="none" stroke={theme.palette.common.darkBlueConstant} strokeMiterlimit="10" points="10,1 22,1 22,23 10,23 " />
                </g>
              </IconSvg>
            </ListItemIcon>
            <ListItemText primary={t('logoutButtonText')} />
          </PocketListItemButton>
        </List>
      </AccountWrapper>
    </ToggleablePanel>
  );
};

export default HeaderUserPocket;
