import Smart from 'hocs/smart';
import MessagingView from './messaging-view';

export default Smart(
  MessagingView,
  state => ({
    userId: state.session.user.id,
    organisationId: state.session.organisationId,
    textMessages: state.reports.textMessages,
  }),
  ac => ({
    displaySnackbar: ac.app.displaySnackbar, // FIXME: DEV-3334
    markRead: ac.reports.markRead,
  })
);
