import Smart from 'hocs/smart';
import Analysisbox from './analysisbox-view';

export default Smart(
  Analysisbox,
  state => ({
    selectedItem: state.app.selectedItem,
    selectedLeg: state.map.selectedLegs[state.map.selectedMapId],
    selectedReport: state.reports.selectedReportPerMap[state.map.selectedMapId],
    selectedMapId: state.map.selectedMapId,
    follow: state.map.assetsAreBeingFollowedOnMaps[state.map.selectedMapId],
    organisationId: state.session.organisationId,
  }),
  ac => ({
    setSelectedReport: ac.reports.setSelectedReport,
    setHighlightedReportId: ac.reports.setHighlightedReportId,
    setFollow: ac.map.setFollow,
    setMostRecentDeviceReport: ac.reports.setMostRecentDeviceReport,
  })
);
