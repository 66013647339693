const MapTemplate = () => ({
  mapboxStyle: 'mapbox://styles/mapbox/satellite-streets-v12',
  thumbnailUrl: '/img/base-layer-thumbnails/satellite-streets-v12.jpg', // TODO: create a specific thumbnail image
  name: 'Mapbox Satellite Streets',
  provider: 'Mapbox',
  dark: true,
  maxzoom: 19,
});

export default MapTemplate;
