import React, { Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useRef } from 'react';
import { Autocomplete, Box, Stack, TextField, Typography, CircularProgress, Chip } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useTranslations } from 'use-intl';
import { useAssetLabel } from 'components/shared/assetLabel';
import { VirtualizedListbox } from 'components/shared/virtualizedAutocomplete';
import { isDefined } from 'utils/type';
import useSerialType from 'hooks/settings/useSerialType';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { AssetGroup } from 'apis/rest/assetGroups/types';
import { CorporateFareOutlined, DatasetOutlined } from '@mui/icons-material';
import { AssetDetails } from './assetDetails';
import { DeviceDetails } from './deviceDetails';
import { getGroupOptionsForAllDevices } from './helpers';

export interface SelectAssetsProps {
  label?: ReactNode
  deviceIds: number[] | undefined
  selectedDeviceIds: number[]
  assetsByDeviceId: Record<number, AssetWithDevice> | undefined
  devicesById: Record<number, DeviceBasic> | undefined
  setSelectedDeviceIds: Dispatch<SetStateAction<number[]>>
  renderCount?: (deviceId?: number) => ReactNode
  disabled?: boolean
  assetGroups?: AssetGroup[]
  isLoading?: boolean
  getGroupOptionsFn?: (assetGroups: AssetGroup[] | undefined, organisationId: string) => Option[]
}
interface AllOption {
  deviceIds: number[],
  optionType: 'all',
  name: string,
}
interface GroupOption {
  deviceIds: number[],
  optionType: 'group',
  name: string,
  id: number,
}
interface DeviceOption {
  device: DeviceBasic,
  deviceIds: number[],
  asset: AssetWithDevice | undefined,
  optionType: 'device',
}

export type Option = AllOption | GroupOption | DeviceOption;
const isGroupOption = (option: Option): option is GroupOption => option.optionType === 'group';
const isDeviceOption = (option: Option): option is DeviceOption => option.optionType === 'device';
const isAllOption = (option: Option): option is AllOption => option.optionType === 'all';

export const SelectAssets = ({
  label,
  deviceIds,
  selectedDeviceIds,
  setSelectedDeviceIds,
  assetsByDeviceId,
  devicesById,
  renderCount,
  disabled = false,
  assetGroups,
  isLoading = false,
  getGroupOptionsFn = getGroupOptionsForAllDevices
}: SelectAssetsProps) => {
  const t = useTranslations('pages.sharing');
  const assetLabel = useAssetLabel();
  const deviceSerialType = useSerialType();
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const organisationId = useOrganisationId();

  const sortByLabel = useCallback(
    (a: Option, b: Option) => {
      if (!isDeviceOption(a) || !isDeviceOption(b)) {
        return 1;
      }
      const labelA = assetLabel(a.asset);
      const labelB = assetLabel(b.asset);
      if (!labelA) return 1;
      if (!labelB) return -1;
      return labelA.toLowerCase().localeCompare(labelB.toLowerCase());
    },
    [assetLabel],
  );

  const options = useMemo(
    () => {
      const opts = deviceIds
        ?.filter(id => id in (devicesById ?? {}))
        .map(id => ({
          device: devicesById?.[id],
          deviceIds: [id],
          asset: assetsByDeviceId?.[id],
          optionType: 'device',
        } as Option)).sort(sortByLabel);

      const allOpts = [{
        optionType: 'all',
        deviceIds,
        name: 'All assets'
      } as Option];

      const groupOpts = getGroupOptionsFn(assetGroups, organisationId);

      return opts ? allOpts.concat(groupOpts).concat(opts) : [];
    },
    [deviceIds, sortByLabel, assetGroups, devicesById, assetsByDeviceId, getGroupOptionsFn, organisationId],
  );

  const filterAssetOptions = createFilterOptions<Option>({
    stringify: option => {
      if (isDeviceOption((option))) {
        const opt = option as DeviceOption;
        return [
          opt.asset?.name,
          opt.asset?.make,
          opt.asset?.model,
          opt.asset?.variant,
          opt.device.make,
          opt.device.model,
        ].filter(s => s).join(' ');
      }
      if (isAllOption((option))) {
        const opt = option as AllOption;
        return opt.name;
      }
      if (isGroupOption((option))) {
        const opt = option as GroupOption;
        return opt.name;
      }
      return String(option);
    }
  });

  const selectedDevices = useMemo(() => {
    const selected = options.reduce<Option[]>((acc, option) => {
      if (isDeviceOption(option)) {
        const index = selectedDeviceIds.indexOf(option.deviceIds.at(0) ?? -1);
        if (index >= 0) acc[index] = option;
      } else if (isAllOption(option)) {
        const index = selectedDeviceIds.indexOf(-1);
        if (index >= 0) acc[index] = option;
      } else if (isGroupOption(option)) {
        const index = selectedDeviceIds.indexOf(-1);
        if (index >= 0) acc[index] = option;
      }
      return acc;
    }, [])
      .filter(isDefined);
    if (selected.some(opt => opt.optionType === 'all')) {
      return selected.filter(opt => opt.optionType === 'all');
    }
    return selected;
  }, [selectedDeviceIds, options]);

  return (
    <Autocomplete
      ref={autocompleteRef}
      filterOptions={filterAssetOptions}
      options={!isLoading ? options : []}
      loading={options === undefined || isLoading}
      loadingText={t('selectAssets.loading')}
      ListboxComponent={VirtualizedListbox}
      renderInput={params => (
        <TextField
          {...params}
          label={label ?? t('selectAssets.label')}
          variant="outlined"
          // manually set font size and line height to override the consequences of our non-standard base font size
          InputProps={{
            ...params.InputProps,
            sx: { input: { height: '23px', lineHeight: '23px' } },
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>)
          }}
          InputLabelProps={{ ...params.InputLabelProps, sx: { lineHeight: '23px' } }}
        />
      )}
      renderOption={(props, option) => {
        if (isAllOption(option)) {
          return (
            <Box {...props} component="li" key="ALL">
              <Stack direction="row" spacing={1} alignItems="center">
                <CorporateFareOutlined sx={{ ml: -0.3 }} fontSize="small" />
                <Stack direction="column" spacing={0}>
                  <Typography>{t('selectAssets.all')}</Typography>
                  <Typography>{t('selectAssets.allDescription')}</Typography>
                </Stack>
              </Stack>
            </Box>
          );
        }
        if (isGroupOption(option)) {
          return (
            <Box {...props} component="li" key="ALL">
              <Stack direction="row" spacing={1} alignItems="center">
                <DatasetOutlined sx={{ ml: -0.3 }} fontSize="small" />
                <Stack direction="column" spacing={0}>
                  <Typography>{option?.name ?? t('selectAssets.noAssetGroupName')}</Typography>
                </Stack>
              </Stack>
            </Box>
          );
        }
        return (
          <Box {...props} component="li" key={option.deviceIds.at(0)}>
            <Stack direction="row" spacing={3} flex="1" alignItems="center">
              <Box flex="1">
                <AssetDetails asset={option.asset} />
              </Box>
              <Box flex="1">
                <DeviceDetails device={option.device} />
              </Box>
              {renderCount && <Box flex="0 0 16ch" textAlign="right">{renderCount(option.deviceIds.at(0))}</Box>}
            </Stack>
          </Box>
        );
      }}
      getOptionLabel={option => {
        if (isAllOption(option)) {
          return t('selectAssets.all');
        }
        if (isDeviceOption(option)) {
          if (option.asset) {
            return assetLabel(option.asset) || t('asset.unnamed');
          }
          return t('asset.unknownWithDevice', { make: option.device.make, model: option.device.model, serial: option.device[deviceSerialType] });
        }
        return '';
      }}
      value={selectedDevices}
      onChange={(_, value) => {
        setSelectedDeviceIds(value.map(v => v.deviceIds).flat() ?? []);
      }}
      disabled={disabled}
      multiple
      fullWidth
      disableCloseOnSelect
      renderTags={(tagValue, getTagProps, ownerState) => {
        const numTags = tagValue.length;
        if (!ownerState.focused) {
          return tagValue.slice(0, 4).map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={option.deviceIds.at(0)}
              label={isDeviceOption(option) ? assetLabel(option.asset) || t('asset.unnamed') : option.name}
            />
          )).concat(
            numTags > 4
              ? (
                <Chip
                  key="more"
                  label={t('more', { n: numTags - 4 })}
                  onClick={() => autocompleteRef.current?.focus()}
                />
              )
              : []
          );
        }
        return tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.deviceIds.at(0)}
            label={isDeviceOption(option) ? assetLabel(option.asset) || t('asset.unnamed') : option.name}
          />
        ));
      }}
    />
  );
};

export default SelectAssets;
