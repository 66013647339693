import Smart from 'hocs/smart';
import DistressView from './distressDialog-view';

export default Smart(
  DistressView,
  () => undefined,
  ac => ({
    fetchDistressIncident: ac.asset.fetchDistressIncident,
  })
);
