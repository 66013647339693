import Smart from 'hocs/smart';
import View, { QuickViewProps } from './quickGroup-view';

type QuickGroupView = (props: Omit<QuickViewProps, 'activeQuery' | 'omniboxOpen'>) => ReturnType<typeof View>

export default Smart(
  View,
  state => ({
    activeQuery: state.app.query,
    omniboxOpen: state.ui.omniboxOpen
  }),
  ac => ({})
) as QuickGroupView;
