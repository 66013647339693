import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Stack,
  Divider,
  Grid,
  Typography, Skeleton
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslations } from 'use-intl';
import { maxBy } from 'lodash/fp';
import { useSelector } from 'react-redux';
import useToggle from 'hooks/useToggle';
import { useAssetReportsNoInactive, useLatestPosition } from 'repositories/reports/hooks';
import { distance } from 'helpers/unitsOfMeasure';
import { useQueryLegs } from 'helpers/legs';
import OpenStreetMapCredit from 'components/shared/openStreetMapCredit';
import { useStartOfDay } from 'hooks/useStartOfDay';
import { eventFilterSelector } from 'selectors/events';
import { useAppDispatch } from 'store/types';
import { setTrailHighlight } from 'actions/map';
import { filterAssetReports } from 'actions/reports';
import Leg from './leg';
import Filter from './filter';
import LegsTotals from './legsTotals';
import JumpToLatestActionButton from './actionButtons/jumpToLatest';
import FilterActionButton from './actionButtons/filter';
import useStyles from './activity-styles';
import { ViewInTripAnalysis } from './actionButtons/viewInTripAnalysis';
import BetweenLegReports from './leg/BetweenLegReports';

interface ACBActivityProps {
  selectedAsset: AssetWithDevice
  reports: Report[]
}

const AssetContextBoxActivity = ({ selectedAsset, reports }: ACBActivityProps) => {
  const classes = useStyles();
  const t = useTranslations('contextbox.asset.activity');
  const [filterEnabled, toggleFilter] = useToggle(false);

  const reportFilter = useSelector<ReduxState, Filter>(eventFilterSelector);
  const dispatch = useAppDispatch();

  const onUpdateFilter = useCallback((nextFilter: Filter) => dispatch(filterAssetReports(selectedAsset?.id, nextFilter)), [dispatch, selectedAsset]);
  const onSetTrailHighlight = useCallback((th: TrailHighlight | null) => dispatch(setTrailHighlight(th)), [dispatch]);

  const startOfDay = useStartOfDay();

  const latestPosition = useLatestPosition(selectedAsset?.id);
  const reportsForAsset = useAssetReportsNoInactive(
    selectedAsset?.id,
    startOfDay.toSeconds()
  );

  const selectActivityGroups = useCallback((legs: Leg[]): ActivityGroup[] => legs
    .sort((l1, l2) => l2.start - l1.start)
    .map((l, i, a) => {
      const reportsInLeg = reportsForAsset.filter(r => r.received >= l.start && r.received <= l.end);
      const previousEnd = a.at(i + 1)?.end;
      const beforeReports = previousEnd
        ? reportsForAsset.filter(r => r.received > previousEnd && r.received < l.start)
        : reportsForAsset.filter(r => r.received < l.start);

      return {
        key: `leg-${l.id}-${selectedAsset?.id}`,
        reports: reportsInLeg,
        beforeReports,
        leg: l,
        length: reportsInLeg
          .filter(r => r.isValid)
          .reduce((past, current, idx, arr) => {
            if (idx === 0) return past;
            const prev = arr[idx - 1];
            return past + distance.distanceTo(current.latitude, current.longitude, prev.latitude, prev.longitude);
          }, 0),
      };
    }), [reportsForAsset, selectedAsset?.id]);

  const { data: activityGroups, isLoading } = useQueryLegs(selectedAsset, reportsForAsset, {
    select: selectActivityGroups
  });

  const afterAllLegsReports = useMemo(() => {
    if (!activityGroups) {
      return undefined;
    }
    if (activityGroups.length === 0) {
      return reportsForAsset;
    }
    const mostRecentLeg = maxBy('leg.start', activityGroups);
    if (!mostRecentLeg?.leg.complete) {
      return undefined;
    }
    return reportsForAsset.filter(r => r.received > mostRecentLeg.leg.end);
  }, [activityGroups, reportsForAsset]);

  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['legs', reportsForAsset[0]?.deviceId],
    });
  }, [reportsForAsset, queryClient]);

  const someLegsForThisAsset = activityGroups?.some(g => g.leg.assetId === selectedAsset?.id) || false;

  return (
    <Box>
      <Box className={classes.moduleHeader}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.moduleHeaderText}>{t('title')}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.actionIconWrapper} pr={1} mt={-0.7}>
            <JumpToLatestActionButton latestPosition={latestPosition} />
            <FilterActionButton onClick={toggleFilter} />
          </Grid>
        </Grid>
      </Box>
      <Filter
        toggleFilter={toggleFilter}
        filtersToggle={filterEnabled}
        reports={reports}
        updateFilter={onUpdateFilter}
        filter={reportFilter}
      />
      <Stack className={classes.moduleContent} spacing={2}>
        {selectedAsset?.id && <ViewInTripAnalysis assetId={selectedAsset.id} startOfDay={startOfDay} /> }
        {(someLegsForThisAsset || isLoading) && (
        <>
          <Divider />
          <LegsTotals activityGroups={activityGroups ?? []} loading={isLoading} />
          <Divider />
        </>
        )}
        {isLoading && [...Array(5).keys()].map(i => <Skeleton key={i} variant="rounded" height={100} />)}
        <Stack>
          {!isLoading && (afterAllLegsReports?.length ?? 0) > 0 && <BetweenLegReports reports={afterAllLegsReports ?? []} />}
          {!isLoading && someLegsForThisAsset && activityGroups?.map((g, i, a) => (
            <>
              <Leg
                key={g.key}
                reports={g.reports}
                leg={g.leg}
                reportFilter={reportFilter}
                setTrailHighlight={onSetTrailHighlight}
              />
              <BetweenLegReports reports={g.beforeReports} atTheEnd={i === a.length - 1} />
            </>
          ))}
        </Stack>
        {!isLoading && someLegsForThisAsset && <OpenStreetMapCredit />}
      </Stack>
    </Box>
  );
};

export default AssetContextBoxActivity;
