/* eslint-disable no-underscore-dangle */
import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { useAssetGroupBy } from 'hooks/settings/useAssetGroupBy';
import { useAssetSortBy } from 'hooks/settings/useAssetSortBy';
import { selectItem } from 'slices/app.slice';
import { useAppDispatch } from 'store/types';
import GroupedQueryResults from './groupedResults';
import useStyles from './results-styles';

interface OmniboxResultsProps {
  organisationId: string,
  selectedMapId: string,
  assignItemToMap: (mapId: string, asset: AssetBasic) => void,
  data: { assets: AssetBasic[] }
  toggleAll?: boolean,
  toggleVisibilityText: (hideAll: boolean) => void,
}

const OmniboxResults = ({
  organisationId,
  selectedMapId,
  assignItemToMap,
  data,
  toggleAll,
  toggleVisibilityText,
}: OmniboxResultsProps): JSX.Element => {
  const classes = useStyles();
  const selectedSortBy = useAssetSortBy();
  const selectedGroupBy = useAssetGroupBy();
  const dispatch = useAppDispatch();

  const selectAsset = useCallback(asset => {
    dispatch(selectItem(asset));
    assignItemToMap(selectedMapId, { ...asset, organisationId });
    // TODO: performance - this use effect is selecting assets when switching maps, this is also happening in mapContainer-view
  }, [assignItemToMap, organisationId, dispatch, selectedMapId]);

  const assets = useCallback(() => data.assets, [data])();

  return (
    <Box id="omniboxResults" className={classes.container}>
      <Box className={classes.listWrapper} data-test="assetContainer">
        {data.assets && (
          <GroupedQueryResults
            results={assets}
            groupBy={selectedGroupBy.assets}
            sortBy={selectedSortBy.assets}
            selectAsset={selectAsset}
            toggleAll={toggleAll}
            toggleVisibilityText={toggleVisibilityText}
          />
        )}
      </Box>
    </Box>
  );
};

export default OmniboxResults;
