const MapTemplate = () => ({
  mapboxStyle: 'mapbox://styles/mapbox/dark-v11',
  thumbnailUrl: '/img/base-layer-thumbnails/dark-map-thumbnail.jpg',
  name: 'Mapbox Dark',
  provider: 'Mapbox',
  dark: true,
  maxzoom: 19,
});

export default MapTemplate;
