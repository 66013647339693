import React, { ReactNode } from 'react';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import { useStaff } from 'hooks/session/useStaff';

interface FeatureGateProps {
  feature: string
  enabled?: ReactNode
  disabled?: ReactNode
  loading?: ReactNode
  allowStaff?: boolean
}

const FeatureGate: React.FC<FeatureGateProps> = ({ feature, enabled, disabled, loading, allowStaff = false }) => {
  const featureAssets = useFeatureAssets(feature);
  const isStaff = useStaff();

  /* eslint-disable react/jsx-no-useless-fragment */
  if (allowStaff && isStaff) return <>{enabled}</>;
  if (featureAssets.isLoading) return <>{loading}</>;
  if (featureAssets.some) return <>{enabled}</>;
  return <>{disabled}</>;
  /* eslint-enable react/jsx-no-useless-fragment */
};

export default FeatureGate;
