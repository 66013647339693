import Smart from 'hocs/smart';
import MessageInputBox from './messageInputBox-view';

export default Smart(
  MessageInputBox,
  state => ({
    username: state.session.user.name,
    organisationId: state.session.organisationId,
    organisationName: state.session.user.memberOf.find((o: MemberOf) => o.id === state.session.organisationId)?.name,
  }),
  ac => ({
    displaySnackbar: ac.app.displaySnackbar, // FIXME: DEV-3334
  })
);
