import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Grid,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent
} from '@mui/material';
import Arrow from '@mui/icons-material/KeyboardArrowDown';
import BootstrapInput from 'components/shared/forms/inputs/bootstrap';
import { useTranslations } from 'use-intl';
import mixpanel from 'mixpanel-browser';
import useFeature from 'hooks/features/useFeature';
import { useAssetLabelKey } from 'components/shared/assetLabel';
import { updateOmniboxSettings } from 'slices/settings/omniboxSettingsSlice';
import { useAppDispatch } from 'store/types';
import { useAssetSortBy } from 'hooks/settings/useAssetSortBy';
import { useAssetGroupBy } from 'hooks/settings/useAssetGroupBy';
import useStyles from './quickGroup-styles';

interface Query {
  id: string,
  name: string
}
export interface QuickViewProps {
  activeQuery: Query,
  listOptionsToggle: boolean,
  omniboxOpen: boolean
}

const QuickView = ({
  activeQuery,
  listOptionsToggle,
  omniboxOpen
}: QuickViewProps): JSX.Element => {
  const assetLabelKey = useAssetLabelKey();
  const dispatch = useAppDispatch();

  const t = useTranslations('omnibox.modules.options');

  const selectedSortBy = useAssetSortBy();
  const selectedGroupBy = useAssetGroupBy();

  const PREDEFINED_GROUPINGS = {
    assets: [
      ...([{ id: 'assetGroup', name: t('assetGroup') }]),
      { id: 'latestActivity', name: t('latestActivity') },
      { id: 'make', name: t('make') },
      { id: 'model', name: t('model') },
      // { id: 'status', name: t('status') },
      { id: 'owner', name: t('owner') },
      { id: 'category', name: t('category') },
      { id: 'watchlistGroup', name: t('watchlist') },
      { id: 'operatorName', name: t('operator') },
    ],
    devices: [
      { id: 'none', name: t('none') },
      { id: 'make', name: t('make') },
      { id: 'model', name: t('model') },
      { id: 'status', name: t('status') }
    ],
    missions: [
      { id: 'none', name: t('none') },
      { id: 'type', name: t('type') }
    ]
  } as Record<string, { id: string, name: string }[]>;

  const PREDEFINED_SORTINGS = {
    assets: [
      { id: 'activity', name: t('activity') },
      { id: 'name', name: t('name', { assetLabelKey }) },
    ],
  } as Record<string, { id: string, name: string }[]>;

  const listOptionsClass = listOptionsToggle ? 'open' : 'closed';
  const omniboxClass = omniboxOpen ? 'omniOpen' : 'omniClosed';

  const classes = useStyles();
  const [query, setQuery] = useState(activeQuery.name.toLowerCase());

  const handleGroupChange = (event: SelectChangeEvent<unknown>): void => {
    if (!event?.target?.value) return;
    dispatch(updateOmniboxSettings({ field: 'assetsGroupBy', value: event.target.value as AssetGroupType }));
    mixpanel.track('Change Group By', { groupBy: event.target.value });
  };

  const handleSortChange = (event: SelectChangeEvent<unknown>): void => {
    if (!event?.target?.value) return;
    dispatch(updateOmniboxSettings({ field: 'assetsSortBy', value: event.target.value as AssetSortType }));
    mixpanel.track('Change Sort By', { sortBy: event.target.value });
  };

  // reset selection on query change
  useEffect(() => {
    setQuery(activeQuery.name.toLowerCase());
  }, [activeQuery.name, query]);

  return (
    <Grid container spacing={0} className={clsx(classes.wrapper, listOptionsClass, omniboxClass)}>
      <Grid item xs={12} sm={6}>
        <InputLabel id="quickgroup" className={classes.label}>{`${t('groupBy')}:`}</InputLabel>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.selectWrapper}>
        <Select
          variant="standard"
          id="quickgroup"
          className={classes.select}
          value={selectedGroupBy[query]}
          onChange={e => handleGroupChange(e)}
          input={<BootstrapInput />}
          IconComponent={() => (<Arrow className={classes.icon} />)}
        >
          {PREDEFINED_GROUPINGS[query].map(group => (
            <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputLabel id="quicksort" className={classes.label}>Sort By: </InputLabel>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.selectWrapper}>
        <Select
          variant="standard"
          id="quicksort"
          className={classes.select}
          value={selectedSortBy[query]}
          onChange={e => handleSortChange(e)}
          input={<BootstrapInput />}
          IconComponent={() => (<Arrow className={classes.icon} />)}
        >
          {PREDEFINED_SORTINGS[query].map(sorting => (
            <MenuItem key={sorting.id} value={sorting.id}>{sorting.name}</MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

export default QuickView;
