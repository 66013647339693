import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FeatureKey = 'map.weather' | 'map.adsb' | 'map.3d' | 'map.markers' | 'map.containmentLines' | 'manage.geofencing' | 'map.firefightingOptions';

interface LabsState {
  featureKey: FeatureKey | undefined
}

const initialState: LabsState = { featureKey: undefined };

export const labsSlice = createSlice({
  name: 'labs',
  initialState,
  reducers: {
    showLabInfo(state, action: PayloadAction<FeatureKey>) {
      state.featureKey = action.payload;
    },
    closeLabInfo(state) {
      state.featureKey = undefined;
    },
  },
  selectors: {
    selectLabsFeatureKey: sliceState => sliceState.featureKey,
  },
});

export const { showLabInfo, closeLabInfo } = labsSlice.actions;
export const { selectLabsFeatureKey } = labsSlice.selectors;

export default labsSlice.reducer;
