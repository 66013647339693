import React from 'react';
import Smart from 'hocs/smart';
import View, { ACBActivityLegProps } from './leg-view';

export default Smart(
  View,
  state => ({
    selectedLeg: state.map.selectedLegs[state.map.selectedMapId],
  }),
  ac => ({
    selectLeg: ac.app.selectLeg,
  })
) as React.FC<Omit<ACBActivityLegProps, 'selectedLeg' | 'selectLeg'>>;
