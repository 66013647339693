import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Box, BoxProps, Button, ButtonGroup, Divider, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { PaperOwnProps } from '@mui/material/Paper/Paper';
import sumBy from 'lodash/fp/sumBy';
import max from 'lodash/fp/max';
import { scaleLinear } from 'd3';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel from 'components/shared/assetLabel';
import { useTranslations } from 'use-intl';
import useVolume from 'hooks/units/useVolume';
import { useSelector } from 'react-redux';
import { selectAssetsNoDrops } from 'slices/statsFilter.slice';
import { useSuppressantColors } from 'components/pages/reporting/firefighting/helpers';
import { DropGroup } from 'apis/rest/firefighting/types';
import { TripBasic } from 'apis/rest/trips/types';

const sumVolume = sumBy<DropGroup>('volumeDroppedLitres');

interface DropsByAssetScorecardProps extends PaperOwnProps {
  assetIds: number[]
  dropGroups: DropGroup[]
  trips: TripBasic[]
  visibleSuppressants: Record<Suppressant, boolean>
  setDropFilter: React.Dispatch<React.SetStateAction<Partial<DropGroup>>>
}

interface SuppressantBarProps {
  dropGroups: DropGroup[]
  visibleSuppressants: Record<Suppressant, boolean>
  getWidth: (drops: DropGroup[]) => string
}

interface StatsDisplayProps {
  tooltipKey?: 'averageDropsPerHour' | 'averageVolumePerHour' | 'averageDropsPerFlightHour' | 'averageVolumePerFlightHour';
  valueKey?: 'nDropsPerHour';
  value: number | string;
}

const StatsDisplay = ({ valueKey, value, tooltipKey }: StatsDisplayProps) => {
  const t = useTranslations('pages.reporting.firefighting.stats');

  if (!tooltipKey) {
    return (
      <Typography minWidth="18ch">{valueKey ? t(valueKey, { n: value }) : value}</Typography>
    );
  }

  return (
    <Box minWidth="18ch">
      <Tooltip title={t(`tooltips.${tooltipKey}`)} placement="bottom">
        <Box display="flex" gap={1}>
          <Typography>{value ? (valueKey ? t(valueKey, { n: value }) : value) : '—'}</Typography>
          <Stack direction="column" justifyContent="center">
            <HelpOutline sx={{ fontSize: '1rem' }} />
          </Stack>
        </Box>
      </Tooltip>
    </Box>
  );
};

const SuppressantBar = ({ dropGroups, visibleSuppressants, getWidth }: SuppressantBarProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const suppressantColor = useSuppressantColors();

  const bySuppressant = dropGroups.reduce<Record<string, DropGroup[]>>((acc, drop) => {
    if (!acc[drop.suppressant]?.push(drop)) acc[drop.suppressant] = [drop];
    return acc;
  }, {});

  const items = (Object.keys(visibleSuppressants) as Suppressant[])
    .map(suppressant => ({ suppressant, drops: bySuppressant[suppressant] ?? [] }));

  const itemsWithDrops = items.filter(item => item.drops.length);

  return (
    <Tooltip hidden={!itemsWithDrops.length} title={(
      <Stack display="grid" gridTemplateColumns="max-content max-content max-content max-content" columnGap={1} rowGap={2} my={1}>
        {itemsWithDrops.filter(item => item.drops.length).map(item => (
          <Fragment key={item.suppressant}>
            <Box bgcolor={suppressantColor[item.suppressant]} width="1rem" height="1rem" borderRadius="50%" />
            <Box>{t(`suppressant.${item.suppressant}`)}</Box>
            <Box>{t('nDrops', { n: item.drops.length })}</Box>
            <Box>{sumVolume(item.drops)?.toFixed(0) ?? 0} L</Box>
          </Fragment>
        ))}
      </Stack>
    )}>
      <Stack
        direction="row"
        bgcolor="common.lightGrey"
        borderRadius="4px"
        overflow="hidden"
        mb={0.5}
        sx={{ transition: 'opacity 300ms', '&:hover': { opacity: 0.7 } }}
      >
        {items.map(item => (
          <Box
            key={item.suppressant}
            height="1.5rem"
            width={getWidth(item.drops)}
            sx={{ transition: 'width 300ms' }}
            bgcolor={suppressantColor[item.suppressant]}
          />
        ))}
      </Stack>
    </Tooltip>
  );
};

const DropsByAssetScorecard = ({ assetIds, dropGroups, trips, sx, visibleSuppressants, setDropFilter, ...props }: DropsByAssetScorecardProps) => {
  const t = useTranslations('pages.reporting.firefighting');
  const volume = useVolume();
  const [metric, setMetric] = useState<'volume' | 'count'>('count');
  const assetsQuery = useGetAssetsList<AssetWithDevice[]>().query;

  const allDropsByAssetId = useMemo(() => dropGroups.reduce<Record<number, DropGroup[]>>((acc, drop) => {
    if (!acc[drop.assetId]?.push(drop)) acc[drop.assetId] = [drop];
    return acc;
  }, {}), [dropGroups]);

  const dropsByAssetId = useMemo(() => dropGroups.filter(dg => visibleSuppressants[dg.suppressant]).reduce<Record<number, DropGroup[]>>((acc, drop) => {
    if (!acc[drop.assetId]?.push(drop)) acc[drop.assetId] = [drop];
    return acc;
  }, {}), [dropGroups, visibleSuppressants]);

  const assetsWithNoDrops = useMemo(() => assetIds.filter(assetId => !allDropsByAssetId[assetId]), [allDropsByAssetId, assetIds]);

  const getWidth = useMemo(() => {
    if (metric === 'volume') {
      const maxAssetDropVolume = max(Object.values(dropsByAssetId).map(sumVolume)) ?? 1;
      const scale = scaleLinear([0, maxAssetDropVolume ?? 1], [0, 100]);
      return (dgs: DropGroup[]) => {
        if (dgs.length === 0 || !visibleSuppressants[dgs[0].suppressant]) { return '0%'; }
        return `${scale(sumVolume(dgs) ?? 0)}%`;
      };
    }

    const maxAssetDropCount = max(Object.values(dropsByAssetId).map(dgs => dgs.flatMap(dg => dg.drops).length)) ?? 1;
    const scale = scaleLinear([0, maxAssetDropCount ?? 1], [0, 100]);
    return (dgs: DropGroup[]) => {
      if (dgs.length === 0 || !visibleSuppressants[dgs[0].suppressant]) { return '0%'; }
      return `${scale(dgs.flatMap(dg => dg.drops).length)}%`;
    };
  }, [metric, dropsByAssetId, visibleSuppressants]);

  const assetNoDropsDisabled = useSelector(selectAssetsNoDrops);
  const relevantAssets = useMemo(() => assetsQuery.data?.filter(asset => {
    if (!assetNoDropsDisabled) {
      return assetIds.includes(asset.id);
    }
    return assetIds.includes(asset.id) && !assetsWithNoDrops.includes(asset.id);
  }), [assetsWithNoDrops, assetIds, assetNoDropsDisabled, assetsQuery.data]);

  const [hoveredId, setHoveredId] = useState<number>();

  const onHover = useCallback((id: number) => () => {
    setDropFilter({ assetId: id });
    setHoveredId(id);
  }, [setDropFilter, setHoveredId]);
  const onUnHover = useMemo(() => () => {
    setDropFilter({});
    setHoveredId(undefined);
  }, [setDropFilter, setHoveredId]);

  const lastAsset = relevantAssets?.at(-1);

  return (
    <Paper elevation={0} {...props} sx={{ ...sx, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <Stack direction="row" justifyContent="space-between" p={3} alignItems="center">
        <Typography fontSize="1.5rem" fontWeight="bold" variant="h2">{t('dropsByAsset')}</Typography>
        <ButtonGroup variant="outlined">
          <Button onClick={() => setMetric('count')} variant={metric === 'count' ? 'contained' : undefined}>{t('dropCount')}</Button>
          <Button onClick={() => setMetric('volume')} variant={metric === 'volume' ? 'contained' : undefined}>{t('volume')}</Button>
        </ButtonGroup>
      </Stack>
      <Divider />
      <Box sx={{ maxHeight: 'inherit', overflowY: 'scroll', overflowX: 'hidden', position: 'relative', mr: -2 }}>
        <Box display="grid" gridTemplateColumns="max-content 1fr">
          {(relevantAssets?.length ?? 0) > 0 ? relevantAssets?.map(asset => {
            const assetDrops = dropsByAssetId[asset.id] ?? [];

            const boxProps: Partial<BoxProps> = {
              onMouseLeave: onUnHover,
              onMouseEnter: onHover(asset.id),
              sx: theme => ({
                transition: 'background-color 100ms',
                backgroundColor: asset.id === hoveredId ? theme.palette.common.lightGrey : 'transparent',
              }),
            };

            return (
              <Fragment key={asset.id}>
                <Box gridColumn="span 2" {...boxProps} py={1} px={2}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <AssetColourMarker assetId={asset.id} />
                    <Typography fontWeight="bold" fontSize="1.2rem" lineHeight="1.5rem" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"><AssetLabel asset={asset} /></Typography>
                    <Typography fontSize="1rem" flex="1" textAlign="end">{asset.make} {asset.model}</Typography>
                  </Stack>
                </Box>
                <Box {...boxProps} pl={2}>
                  <Typography fontSize="1rem" minWidth="10ch" textAlign="left">{metric === 'volume' ? volume.create(sumVolume(assetDrops)).format() : t('nDrops', { n: assetDrops.length })}</Typography>
                </Box>
                <Box {...boxProps} pr={2} pb={1}>
                  <SuppressantBar dropGroups={assetDrops} getWidth={getWidth} visibleSuppressants={visibleSuppressants} />
                </Box>
                {asset !== lastAsset && <Divider sx={{ gridColumn: '1 / -1' }} />}
              </Fragment>
            );
          }) : <p>{t('noAssetsToDisplay')}</p>}
        </Box>
      </Box>
    </Paper>
  );
};

export default DropsByAssetScorecard;
