import Smart from 'hocs/smart';
import { setOrganisationId } from 'slices/session/session.slice';
import OrganisationSelectorView from './organisationSelector-view';

export default Smart(
  OrganisationSelectorView,
  state => ({
    organisationId: state.session.organisationId,
    organisations: state.session.user?.memberOf,
    selectedMapId: state.map.selectedMapId,
    orgHomeBases: state.settings.map.homeBases,
  }),
  ac => ({
    setOrganisation: setOrganisationId,
    jumpToHomeBase: ac.map.jumpToHomeBase,
    setSelectedGroupBy: ac.app.setOmniboxGroupBy, // FIXME: DEV-3334
    setFollow: ac.map.setFollow
  })
);
