import Smart from 'hocs/smart';
import MapContainerView from './mapContainer-view';

export default Smart(
  MapContainerView,
  state => ({
    maps: state.map.maps,
    mapLayout: state.map.selectedMapLayout,
    selectedMapId: state.map.selectedMapId,
    selectedAssets: state.map.selectedAssets,
    defaultMapSettings: state.map.maps.default,
    highContrastControls: state.settings.map.highContrastControls
  }),
  ac => ({
    updateMapLayout: ac.map.updateMapLayout,
    selectMap: ac.map.selectMap,
  })
);
