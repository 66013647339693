import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, FormControl, Container, FormControlLabel, Paper, Stack, Switch, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import { DateTime } from 'luxon';
import { Outlet, Route, Routes, useMatch, useSearchParams } from 'react-router-dom';
import sortBy from 'lodash/fp/sortBy';
import Page from 'components/pages/page';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import { updateSetting } from 'actions/settings';
import { useGetTripsForAssets } from 'apis/rest/trips/hooks';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { isAssetWithDevice, isAviationAsset } from 'helpers/assets';
import AssetLabel from 'components/shared/assetLabel';
import AssetColourMarker from 'components/shared/assetColourMarker';
import useTimezone from 'hooks/session/useTimezone';
import { selectStatsFilterEnabled, toggleAssetsNoDrops, toggleStatsFilterEnabled, selectAssetsNoDrops } from 'slices/statsFilter.slice';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { selectDevicesById } from 'components/pages/sharing/helpers';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import { SelectAssets } from 'components/pages/sharing/selectAssets';
import { useAssetsByDeviceId } from 'hooks/assets/useAssetsByDeviceId';
import { useDeviceIdByAssets } from 'hooks/assets/useDeviceIdByAssets';
import { stringify as csvStringify, Input, Options } from 'csv-stringify/browser/esm/sync';
import { downloadCSV } from 'utils/download';
import { parseSearchParams } from 'utils/parseSearchParams';
import FeaturePageStaffAccessAlert from 'components/shared/pageStaffAccessAlert/feature';
import LinkAbove from 'components/shared/linkAbove';
import DropsBySuppressantScorecard from './dropsBySuppressantScorecard';
import DropsScorecard from './dropsScorecard';
import { DateRangeSelection } from '../tripAnalysis/dateRangeSelection';
import { DropFillHeatmapScorecard } from './dropFillHeatmapScorecard';
import { useCSVFirefightingFormatter } from './statistics';

type Query = ReduxState['settings']['tripAnalysis']['query'];

const isQueryValid = (query: Query): query is Required<Query> => !!query.assets?.length && query.from !== undefined && query.until !== undefined;

const NO_ASSETS: never[] = [];

const selectDropsById = (trips: Trip[]): Record<number, Drop> => (
  trips
    .flatMap(trip => trip.drops)
    .reduce<Record<number, Drop>>((acc, drop) => {
      acc[drop.id] = drop;
      return acc;
    }, {})
);

const selectTripsWithDrops = (trips: Trip[]) => trips.filter(trip => trip.drops.length > 0);

export interface OutletContextValue {
  visibleSuppressants: Record<Suppressant, boolean>
  setVisibleSuppressants: Dispatch<SetStateAction<Record<Suppressant, boolean>>>
  setHoveredDropGroup: Dispatch<SetStateAction<string | undefined>>
  setSelectedDropGroup: Dispatch<SetStateAction<string | undefined>>
  assetIds: number[]
  drops: Drop[]
  trips: Trip[]
}

const selectAviationAssetsWithDevices = (assets: AssetBasic[]) => assets.filter((f): f is AssetWithDevice => isAviationAsset(f) && isAssetWithDevice(f));

const FirefightingLayout = (): JSX.Element => {
  const t = useTranslations('pages.reporting.firefighting');

  const matches = useMatch('/insights/firefighting/asset/:assetId');
  const persistedQuery = useSelector<ReduxState, Query>(state => state.settings.tripAnalysis.query);
  const timezone = useTimezone();
  const [searchParams, setSearchParams] = useSearchParams({
    from: persistedQuery?.from ? DateTime.fromMillis(persistedQuery.from).toISODate() : DateTime.now().startOf('day').minus({ days: 6 }).toISODate(),
    until: persistedQuery?.until ? DateTime.fromMillis(persistedQuery.until).toISODate() : DateTime.now().endOf('day').toISODate()
  });
  const assetId = matches ? parseInt(matches?.params?.assetId ?? '0', 10) : undefined;
  const { fromUrl, untilUrl, devicesUrl } = parseSearchParams(searchParams, timezone);
  const query = useMemo<Query & { from: number, until: number }>(() => ({
    ...persistedQuery,
    from: fromUrl ?? persistedQuery.from ?? DateTime.now().startOf('day').minus({ days: 6 }).toMillis(),
    until: untilUrl ?? persistedQuery.until ?? DateTime.now().endOf('day').toMillis(),
  }), [persistedQuery]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<number[]>(devicesUrl);
  const { createCSVSummaryData, createCSVAssetData } = useCSVFirefightingFormatter();

  const tz = useTimezone();
  const [datePickerRange, setDatePickerRange] = useState<{ from: string, until: string }>({
    from: DateTime.fromMillis(query.from).toISODate(),
    until: DateTime.fromMillis(query.until).toISODate(),
  });

  const dispatch = useDispatch();
  const setQuery = useCallback((newQuery: Query) => dispatch(updateSetting('tripAnalysis', 'query', newQuery)), [dispatch]);

  const devicesByIdQuery = useGetDevicesList({ select: selectDevicesById }).query;
  const assetGroupsQuery = useGetAssetGroupsForOrganisation();

  const assetIds = useMemo(() => (assetId === undefined ? (query?.assets ?? NO_ASSETS) : [assetId]), [assetId, query?.assets]);

  const dropsQuery = useGetTripsForAssets<Record<number, Drop>>(
    assetIds,
    query.from,
    query.until,
    { select: selectDropsById },
  );

  const drops = useMemo(() => (
    dropsQuery.data ? sortBy(['assetId', 'startTime'], Object.values(dropsQuery.data)) : []
  ), [dropsQuery.data]);

  const tripsQuery = useGetTripsForAssets<Record<number, Trip>>(
    assetIds,
    query.from,
    query.until,
    { select: selectTripsWithDrops }
  );

  const trips = useMemo(() => (
    tripsQuery.data ? sortBy(['assetId', 'startTime'], Object.values(tripsQuery.data)) : []
  ), [tripsQuery.data]);

  const setDateRange = useCallback((from: string, until: string) => {
    setDatePickerRange({ from, until });
    const fromMillis = DateTime.fromISO(from, { zone: tz }).startOf('day').toMillis();
    const untilMillis = DateTime.fromISO(until, { zone: tz }).endOf('day').toMillis();
    setQuery({ ...query, from: fromMillis, until: untilMillis });
    setSearchParams(prev => {
      const newParams = new URLSearchParams(prev);
      newParams.set('from', from);
      newParams.set('until', until);
      return newParams;
    });
  }, [setQuery, query, tz, setSearchParams]);

  const statsFilterEnabled = useSelector(selectStatsFilterEnabled);
  const assetNoDropsEnabled = useSelector(selectAssetsNoDrops);

  const [visibleSuppressants, setVisibleSuppressants] = useState<Record<Suppressant, boolean>>({
    Water: true,
    FreshWater: true,
    SaltWater: true,
    Retardant: true,
    Foam: true,
    Gel: true,
    Unknown: true,
  });

  const [hoveredDropGroup, setHoveredDropGroup] = useState<string | undefined>();
  const [selectedDropGroup, setSelectedDropGroup] = useState<string | undefined>();

  const assetsQuery = useGetAssetsList<AssetWithDevice[]>({ select: selectAviationAssetsWithDevices }).query;

  const assets = assetsQuery.data ?? NO_ASSETS;
  const deviceIds = useDeviceIdByAssets(assets);
  const assetsByDeviceId = useAssetsByDeviceId(assets);

  const selectedAsset = assetsQuery.data?.find(asset => asset.id === assetId);

  const outletContextValue = useMemo<OutletContextValue>(() => ({
    visibleSuppressants,
    setVisibleSuppressants,
    setHoveredDropGroup,
    setSelectedDropGroup,
    assetIds,
    drops,
    trips,
  }), [visibleSuppressants, setHoveredDropGroup, setSelectedDropGroup, setVisibleSuppressants, assetIds, drops, trips]);

  useEffect(() => {
    setDateRange(datePickerRange.from, datePickerRange.until);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tz]);

  useEffect(() => {
    const selectedAssets = assets.filter(a => selectedDeviceIds.includes(a.deviceId)).map(a => a.id);
    setQuery({ ...query, assets: selectedAssets });
    setSearchParams(prev => ({ ...Object.fromEntries(prev.entries()), devices: selectedDeviceIds.map(String) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeviceIds, assets]);

  const devicesById = devicesByIdQuery.data ?? [];
  const assetGroups = assetGroupsQuery.data;

  const isLoading = devicesByIdQuery.isFetching || assetGroupsQuery.isFetching || assetsQuery.isFetching;

  const downloadCSVData = async () => {
    let records: Input;
    let options: Options;
    if (selectedAsset) {
      [records, options] = await createCSVAssetData(selectedAsset, trips, timezone, visibleSuppressants);
    } else {
      [records, options] = createCSVSummaryData(assets, selectedDeviceIds, drops, trips, visibleSuppressants);
    }
    const csvString = csvStringify(records, options);

    const dateFormat = 'yyyy.MM.dd';
    const dayFrom = DateTime.fromMillis(query.from).setZone(timezone).toFormat(dateFormat);
    const dayUntil = DateTime.fromMillis(query.until).setZone(timezone).toFormat(dateFormat);

    let exportedFilename: string;
    if (selectedAsset) {
      exportedFilename = `AerialFirefighting-${selectedAsset.name}-${dayFrom}-${dayUntil}`.trim();
    } else {
      exportedFilename = `AerialFirefightingSummary-${dayFrom}-${dayUntil}`.trim();
    }

    downloadCSV(exportedFilename, csvString);
  };

  if (devicesByIdQuery.isLoading) {
    return (<LoadingIcon size={5} />);
  }

  return (
    <Page title={t('summaryTitle')}>
      <SettingsMenuPageWrapper p={8} sx={{ scrollbarGutter: 'stable' }}>
        <Container maxWidth={false}>
          <Box>
            <Routes>
              <Route index element={(
                <>
                  <LinkAbove />
                  <Typography variant="h1" gutterBottom>{t('summaryTitle')}</Typography>
                  <Typography paragraph>{t('description')}</Typography>
                </>
              )} />
              <Route path="asset/:assetId" element={(
                <>
                  <LinkAbove />
                  <Typography variant="h1" gutterBottom>{t('assetTitle')}</Typography>
                  <Typography paragraph>{t('description')}</Typography>
                </>
              )} />
            </Routes>
          </Box>
          <FeaturePageStaffAccessAlert feature="reporting.aerialFirefightingReport" render={content => (
            <Box mb={3}>{content}</Box>
          )} />
          <Paper sx={{ mb: 3 }} elevation={0}>
            <Box display="grid" gridTemplateColumns="1fr auto" gap={2} p={3}>
              <Routes>
                <Route index element={(
                  <FormControl variant="outlined" sx={{ flex: 1 }}>
                    <SelectAssets
                      label={t('selectAssets.label')}
                      deviceIds={deviceIds}
                      selectedDeviceIds={selectedDeviceIds}
                      assetsByDeviceId={assetsByDeviceId}
                      devicesById={devicesById}
                      setSelectedDeviceIds={value => {
                        setSelectedDeviceIds(value);
                      }}
                      disabled={false}
                      assetGroups={assetGroups}
                      isLoading={isLoading}
                />
                  </FormControl>
            )} />
                <Route path="asset/:assetId" element={selectedAsset ? (
                  <Box flex={1} alignSelf="center">
                    <Stack direction="row" alignItems="center" spacing={1} mb={0.5}>
                      <AssetColourMarker assetId={selectedAsset.id} />
                      <Typography fontWeight="bold" fontSize="1.5rem" lineHeight="1.5rem" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden"><AssetLabel asset={selectedAsset} /></Typography>
                    </Stack>
                    <Typography fontSize="1rem">{selectedAsset.make} {selectedAsset.model} {selectedAsset.variant}</Typography>
                  </Box>
                ) : <Box flex={1} />} />
              </Routes>
              <FormControl>
                <DateRangeSelection
                  assets={selectedAsset ? [selectedAsset] : []}
                  from={datePickerRange.from}
                  until={datePickerRange.until}
                  onChange={setDateRange}
            />
              </FormControl>
              <FormControl>
                <Box>
                  <FormControlLabel control={(
                    <Switch
                      checked={statsFilterEnabled}
                      onChange={() => dispatch(toggleStatsFilterEnabled())}
                      inputProps={{ 'aria-label': t('toggleStatsFilterEnabled') }}
                />
              )} label={t('toggleStatsFilterEnabled')} />
                  <FormControlLabel control={(
                    <Switch
                      checked={assetNoDropsEnabled}
                      onChange={() => dispatch(toggleAssetsNoDrops())}
                      inputProps={{ 'aria-label': t('toggleAssetsNoDrops') }}
                />
              )} label={t('toggleAssetsNoDrops')} />
                </Box>
              </FormControl>
              <Button disabled={!isQueryValid(query) || dropsQuery.isLoading} onClick={downloadCSVData} sx={{ gridColumn: 2, gridRow: 2 }} size="large" variant="contained">{t('downloadCSV')}</Button>
            </Box>
          </Paper>

          {isQueryValid(query) && dropsQuery.isLoading ? (
            <Paper elevation={0}>
              <Stack px={3} direction="row" justifyContent="space-between" height="4rem">
                <Stack justifyContent="center">
                  <Typography>{t('loading')}</Typography>
                </Stack>
              </Stack>
            </Paper>
          ) : (
            <Box display="grid" gridTemplateColumns={{ lg: '1fr 1fr 1fr' }} gap={3}>
              <DropsScorecard drops={drops} trips={trips} />
              <DropsBySuppressantScorecard drops={drops} visibleSuppressants={visibleSuppressants} setVisibleSuppressants={setVisibleSuppressants} />
              {drops.length > 0 && (
              <DropFillHeatmapScorecard
                trips={trips}
                hoveredDropGroup={hoveredDropGroup}
                selectedDropGroup={selectedDropGroup}
                visibleSuppressants={visibleSuppressants}
            />
              )}
              <Outlet context={outletContextValue} />
            </Box>
          )}
        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default FirefightingLayout;
