import Smart from 'hocs/smart';
import DatePickerView from './datePicker-view';

export default Smart(
  DatePickerView,
  state => ({
    selectedAsset: state.app.selectedItem,
    organisationId: state.session.organisationId
  }),
  ac => ({
  })
);
