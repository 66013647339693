import Smart from 'hocs/smart';
import GroupedResultsView from './groupedResults-view';

export default Smart(
  GroupedResultsView,
  state => ({
    hiddenInactiveAssets: state.map.hiddenInactiveAssets,
    hiddenAssetGroups: state.map.hiddenAssetGroups,
    activeQuery: state.app.query,
    hiddenAssets: state.map.hiddenAssets,
    selectedItemId: state.app.selectedItem?.id
  }),
  ac => ({
    hideAssetsGroup: ac.map.hideAssetsGroup,
    removeFromHiddenAssetGroups: ac.map.removeFromHiddenAssetGroups,
    hideAssetsOnMap: ac.map.hideAssetsOnMap,
    showAssetsOnMap: ac.map.showAssetsOnMap,
  })
);
