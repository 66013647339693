import airac from 'utils/airac';

const cache = {};

export default language => {
  if (!cache[language]) {
    cache[language] = {
      version: 8,
      name: 'SkyVector IFR Hi Map',
      provider: 'SkyVector',
      sources: {
        'skyVector-ifr-hi': {
          type: 'raster',
          tiles: [
            `${import.meta.env.VITE_SERENITY_TILE_PROXY}/skyvector/hi/${airac}/{z}/{x}/{y}.jpg`
          ],
          // mapbox uses this one :(
          maxzoom: 9,
          minzoom: 3,
          tileSize: 256
        }
      },
      layers: [{
        id: 'ifr-hi',
        type: 'raster',
        source: 'skyVector-ifr-hi'
      }],
      thumbnailUrl: '/img/base-layer-thumbnails/ifr-map-thumbnail.jpg',
      dark: false,
    };
  }

  return cache[language];
};
