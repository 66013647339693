import Smart from 'hocs/smart';
import UserInvitation from './userInvitation-view';

export default Smart(
  UserInvitation,
  state => ({
  }),
  ac => ({
    displaySnackbar: ac.app.displaySnackbar, // FIXME: DEV-3334
  })
);
