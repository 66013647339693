const MapTemplate = () => ({
  mapboxStyle: 'mapbox://styles/mapbox/streets-v12',
  thumbnailUrl: '/img/base-layer-thumbnails/streets-v12.jpg',
  name: 'Mapbox Streets',
  provider: 'Mapbox',
  dark: false,
  maxzoom: 19,
});

export default MapTemplate;
