import React, { useCallback, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';
import DateRangePicker from 'components/shared/dateRangePicker';
import { useGetTripSummariesForAssets } from 'apis/rest/trips/hooks';
import useTimezone from 'hooks/session/useTimezone';
import { useAssetLabel } from 'components/shared/assetLabel';

interface DateRangeSelectionProps {
  assets: AssetWithDevice[]
  from: string
  until: string
  onChange: (from: string, until: string) => void
}

export const DateRangeSelection = ({ assets, from, until, onChange }: DateRangeSelectionProps) => {
  const t = useTranslations('pages.reporting');
  const timezone = useTimezone();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [month, setMonth] = useState<string>(DateTime.now().toISODate());

  const tripSummaryQuery = useGetTripSummariesForAssets(
    assets.map(a => a.id),
    DateTime.fromISO(month).setZone(timezone).startOf('month').toMillis(),
    DateTime.fromISO(month).setZone(timezone).endOf('month').toMillis()
  );

  const setDateRange = useCallback((value: { start: string, end: string }) => {
    onChange(value.start, value.end);
  }, [onChange]);

  const allowedDateRange = useMemo(() => ({
    end: DateTime.now().toISODate()
  }), []);

  let dateRangeText = DateTime.fromISO(from).toFormat('d LLL y');
  if (from !== until) {
    dateRangeText = t('dateRange', {
      from: dateRangeText,
      until: DateTime.fromISO(until).toFormat('d LLL y'),
    });
  }

  const assetLabel = useAssetLabel();

  const getActivityIndicators = useCallback((day: string) => {
    if (!tripSummaryQuery.data) { return []; }
    const dayInTz = DateTime.fromISO(day).setZone(timezone);
    const start = dayInTz.startOf('day').toMillis();
    const end = dayInTz.endOf('day').toMillis();
    return assets.flatMap(a => {
      const wasActive = tripSummaryQuery.data.some(summary => a.id === summary.assetId && ((summary.day <= start && summary.lastEnd >= start) || (summary.day <= end && summary.day >= start && summary.firstStart <= end)));
      return wasActive ? [{ color: a.colour, id: a.id, name: assetLabel(a) }] : [];
    });
  }, [assetLabel, assets, timezone, tripSummaryQuery.data]);

  return (
    <>
      <TextField
        label={t('date')}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onClick={() => setIsDatePickerOpen(true)}
        value={dateRangeText}
        inputProps={{ readOnly: true }}
        InputProps={{ sx: { height: '56px' } }}
      />
      <DateRangePicker
        open={isDatePickerOpen}
        setOpen={setIsDatePickerOpen}
        value={{
          start: from,
          end: until,
        }}
        onChange={setDateRange}
        allowedRange={allowedDateRange}
        maxRangeLength={183}
        renderTitle={({ pendingValue }) => {
          if (pendingValue.end && pendingValue.end !== pendingValue.start) {
            return t('dateRange', {
              from: DateTime.fromISO(pendingValue.start).toFormat('d LLL y'),
              until: DateTime.fromISO(pendingValue.end).toFormat('d LLL y'),
            });
          }
          if (pendingValue.start) {
            return DateTime.fromISO(pendingValue.start).toFormat('d LLL y');
          }
          return null;
        }}
        getActivityIndicators={getActivityIndicators}
        onMonthChange={setMonth}
      />
    </>
  );
};
