import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Tooltip, SvgIcon, Stack, Theme,
  useTheme, Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import useTimezone from 'hooks/session/useTimezone';
import filterByText from 'utils/textFilter';
import moment from 'utils/moment';
import { fetchOrganisationJobs } from 'apis/rest/jobs';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getSelectedDay, getTextFilter, setTextFilter } from 'slices/app.slice';
import Search from './modules/search';
import DatePicker from './modules/datePicker';
import OmniboxResults from './modules/results';
import LoadingComponent from './omnibox-results-loading';
import QuickGroup from './modules/quickGroup';

const omniboxWrapperStyle: SxProps<Theme> = {
  position: 'relative',
  maxWidth: 'var(--omnibox-width)',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 0%',
  zIndex: 3,
  transition: 'all 0.05s'
} as const;

const omniboxStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: 'background.omniboxBackground',
  boxShadow: 'rgba(0, 0, 0, 0.1) 12px 0px 12px -12px',
  zIndex: 3,
  '& > *': {
    p: 0
  }
} as const;

const collapseButtonStyle: SxProps<Theme> = {
  position: 'absolute',
  display: 'flex',
  pt: '16px',
  pb: '16px',
  pr: '6px',
  pl: '4px',
  top: 16,
  right: -26,
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  outline: '1px solid rgba(255,255,255,.2)',
  cursor: 'pointer',
  backgroundColor: 'rgba(0,0,0,.4)', // TODO: MOVE OUT
  zIndex: 1,
  transition: 'all 0.05s',
  transform: 'translateX(-16px)',
  '&:hover': {
    transform: 'translateX(0px)',
  }
} as const;

const queryContainerStyle: SxProps<Theme> = {
  p: 3,
  backgroundColor: 'background.omniboxBackground',
  color: 'common.white',
  overflow: 'hidden'
} as const;

const listOptionsButtonActiveStyle: SxProps<Theme> = {
  backgroundColor: 'omnibox.highlight',
  boxShadow: 'none',
  '& polygon, & rect': {
    fill: 'common.white'
  }
} as const;

const optionButtonStyle: SxProps<Theme> = {
  cursor: 'pointer',
  display: 'flex',
  color: 'common.whiteConstant',
  backgroundColor: 'omnibox.overlay',
  minHeight: 30,
  borderRadius: 30,
  zIndex: 0,
  pl: 2,
  pr: 2,
  pt: 1 / 2,
  pb: 1 / 2,
  lineHeight: 1,
  transition: 'all 0.15s',
  '&:hover': {
    backgroundColor: 'omnibox.overlayHover'
  },
} as const;

const visibilityTextStyle: SxProps<Theme> = {
  color: 'common.whiteConstant',
  lineHeight: 1,
} as const;

const Omnibox = (): JSX.Element => {
  const t = useTranslation('omnibox.settings');
  const [showListOptions, toggleListOptions] = useState(false);
  const [toggleAll, setToggleAll] = useState<boolean | null>(null);
  const [toggleAllText, setToggleAllText] = useState(true);

  const highContrastControls = useSelector<ReduxState, ReduxState['settings']['map']['highContrastControls']>(state => state.settings.map.highContrastControls);
  const organisationId = useSelector<ReduxState, ReduxState['session']['organisationId']>(state => state.session.organisationId);
  const textFilter = useSelector(getTextFilter);
  const omniboxOpen = useSelector<ReduxState, ReduxState['ui']['omniboxOpen']>(state => state.ui.omniboxOpen);

  const timezone = useTimezone();
  const selectedDay = useSelector(getSelectedDay);
  const now = useMemo(() => (selectedDay ? moment.tz(selectedDay, timezone) : null), [selectedDay, timezone]);
  const isDarkMode = useTheme().palette.mode === 'dark';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAssetList = useGetAssetsList().query;

  const jobsEnabled = useFeatureAssets('assets.jobs').some;
  const getOrganisationJobs = useQuery(['jobs', organisationId, now], () => fetchOrganisationJobs(organisationId, now ?? undefined), {
    refetchInterval: 30 * 1000, // 30 Seconds.
    refetchIntervalInBackground: true,
    enabled: jobsEnabled
  });

  const dataWithJobs = useMemo(() => {
    const data: {assets: AssetBasic[] } = { assets: [] };
    if (getAssetList.isSuccess) {
      // Hacky but good luck otherwise. We need to add job data to assets for searching
      const nowDate = now ? moment(now).toDate() : new Date();
      const yesterday = new Date(nowDate.getTime() - 86400000);
      const orgJobs = getOrganisationJobs.isSuccess ? getOrganisationJobs.data : [];
      data.assets = getAssetList.data
        .filter(a => a.deviceId)
        .map(asset => ({
          ...asset,
          jobs: orgJobs?.filter(job => job.assetIds.includes(asset.id.toString()) && (!job.endUTC || new Date(job.endUTC) > yesterday)) || []
        }));
    }
    return data;
  }, [getAssetList, getOrganisationJobs, now]);
  const filteredData = useMemo(() => filterByText(dataWithJobs, textFilter), [textFilter, dataWithJobs]);
  const handleToggleListOptions = (): void => toggleListOptions(!showListOptions);

  const handleSetTextFilter = useCallback((text: string) => {
    dispatch(setTextFilter(text));
    if (text === '') {
      navigate('/');
    }
  }, [dispatch, navigate]);

  const toggleOmnibox = useCallback(
    () => dispatch({ type: 'TOGGLE_OMNIBOX' }),
    [dispatch]
  );

  const allVisibilityIcon = (
    <Tooltip title={toggleAllText ? t('hideAll') : t('showAll')}>
      <Box
        role="button"
        sx={{ ...optionButtonStyle }}
        onClick={() => setToggleAll(!toggleAll)}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
          {toggleAllText
            ? (
              <>
                <VisibilityOff sx={{ color: 'common.whiteConstant' }} />
                <Typography sx={{ ...(visibilityTextStyle) }}>{t('hideAll')}</Typography>
              </>
            )
            : (
              <>
                <Visibility sx={{ color: 'common.whiteConstant' }} />
                <Typography sx={{ ...(visibilityTextStyle) }}>{t('showAll')}</Typography>
              </>
            )}
        </Stack>
      </Box>
    </Tooltip>
  );

  return (
    <Box aria-label="omniboxWrapper" sx={{ ...(omniboxWrapperStyle), ...(!(omniboxOpen) && { maxWidth: '0px' }) }}>
      <Box aria-label="omnibox" sx={omniboxStyle} component="section">
        <Box sx={{ overflow: 'hidden' }}>
          <Stack spacing={0} direction="column" alignItems="stretch" justifyContent="center" sx={{ ...(queryContainerStyle) }}>
            <Search value={textFilter} setValue={handleSetTextFilter} />
            <DatePicker />
            <Stack sx={{ mt: 2 }} direction="row" alignItems="start" justifyContent="space-between" spacing={1}>
              <Box sx={{ ...(optionButtonStyle), ...(showListOptions && listOptionsButtonActiveStyle) }} color="primary" aria-label="add" onClick={handleToggleListOptions}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                  <SvgIcon width="12" height="12" viewBox="0 0 12 12" fontSize="small" sx={{
                    fontSize: '0.85rem',
                    '& path, & polygon, & rect': {
                      fill: isDarkMode ? showListOptions ? 'black' : 'white' : 'common.whiteConstant'
                    }
                  }}>
                    <polygon points="10 0 8 0 8 2 6 2 6 4 12 4 12 2 10 2 10 0" />
                    <rect x="8" y="5" width="2" height="7" />
                    <polygon points="0 10 2 10 2 12 4 12 4 10 6 10 6 8 0 8 0 10" />
                    <rect x="2" width="2" height="7" />
                  </SvgIcon>
                  <Typography sx={{
                    color: isDarkMode ? showListOptions ? 'black' : 'white' : 'common.whiteConstant',
                    lineHeight: 1,
                  }}>{t('groupAndSort')}
                  </Typography>
                </Stack>
              </Box>
              {allVisibilityIcon}
            </Stack>
          </Stack>
        </Box>
        <QuickGroup listOptionsToggle={showListOptions} />
        {(getAssetList.isSuccess && getAssetList.data)
          ? (
            <OmniboxResults
              data={filteredData}
              toggleAll={toggleAll}
              toggleVisibilityText={(hideAll: boolean) => setToggleAllText(hideAll)}
          />
          )
          : <LoadingComponent /> }
      </Box>
      <Tooltip
        title={omniboxOpen ? t('collapsePanel') : t('expandPanel')}
        placement="right"
      >
        <Box aria-label="collapseButton" sx={{ ...(collapseButtonStyle), backgroundColor: highContrastControls ? 'mapControls.bgHighContrast' : 'rgba(0,0,0,.4)' }} onClick={toggleOmnibox} role="button">
          <SvgIcon sx={{ transition: 'all 0.05s', ...(!omniboxOpen && { transform: 'rotate(180deg)' }) }} width="16" height="16" viewBox="0 0 16 16">
            <polygon fill="#fff" points="10,13.4 4.6,8 10,2.6 11.4,4 7.4,8 11.4,12" />
          </SvgIcon>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default Omnibox;
