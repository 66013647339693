const MapTemplate = () => ({
  mapboxStyle: 'mapbox://styles/mapbox/satellite-v9',
  thumbnailUrl: '/img/base-layer-thumbnails/satellite-v9.jpg',
  name: 'Mapbox Satellite',
  provider: 'Mapbox',
  dark: true,
  maxzoom: 19,
});

export default MapTemplate;
