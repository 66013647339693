import Smart from 'hocs/smart';
import ReactMapGLView from './reactmapgl-map-view';

export default Smart(
  ReactMapGLView,
  (state, ownProps) => ({
    additionalRenders: state.positions.maps[ownProps.config.id]?.viewportBounds?.additionalRenders,
    measurementMarker: state.map.measurementMarkers[ownProps.config.id]?.currentMeasurementMarker,
    appSelectedItemId: state.app.selectedItem?.id,
    thisMapSelectedItemId: state.map.selectedAssets[ownProps.config.id]?.id,
    hiddenAssets: state.map.hiddenAssets,
    hiddenInactiveAssets: state.map.hiddenInactiveAssets,
    selectedReport: state.reports.selectedReportPerMap[ownProps.config.id],
    selectedLeg: state.map.selectedLegs[ownProps.config.id],
    contextboxOpen: state.ui.contextboxOpen,
    mapLayout: state.map.selectedMapLayout
  }),
  ac => ({
    assignMarkerToAsset: ac.map.assignMarkerToAsset,
    assignItemToMap: ac.map.assignItemToMap,
    setHighlightedReportId: ac.reports.setHighlightedReportId,
    hideAssetsOnMap: ac.map.hideAssetsOnMap,
    showAssetsOnMap: ac.map.showAssetsOnMap,
    updateHiddenInactiveAssets: ac.map.updateHiddenInactiveAssets,
    displaySnackbar: ac.app.displaySnackbar, // FIXME: DEV-3334
    setSelectedReport: ac.reports.setSelectedReport
  })
);
