/* eslint-disable @typescript-eslint/ban-types */
import { ComponentProps } from 'react';
import Smart from '../../../hocs/smart';
import PersistentTable from './persistentTable-view';

type SmartPersistentTableProps<RowData extends object> = Omit<ComponentProps<typeof PersistentTable<RowData>>, 'rowsPerPage' | 'updateSetting'>;
type SmartPersistentTable = <RowData extends object>(props: SmartPersistentTableProps<RowData>) => ReturnType<typeof PersistentTable<RowData>>

export default Smart(
  PersistentTable,
  state => ({
  }),
  ac => ({
    updateSetting: ac.settings.updateSetting
  })
) as SmartPersistentTable;
