import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import { ErrorBoundary } from 'react-error-boundary';
import useFeatures from 'hooks/features/useFeatures';
import Maps from 'components/maps';
import Translation from 'components/shared/translate';
import { MapProvider } from 'react-map-gl';
import { noticeError } from 'helpers/newRelic';
import { setFollow, closeMap, setBaseLayer } from 'actions/map';
import { setSelectedReport } from 'actions/reports';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useAppDispatch } from 'store/types';
import {
  selectMeasurementState,
  setMeasurementState,
  toggleMeasurementState
} from 'slices/map/multiPointMeasurement.slice';
import MapControls from './modules/controls';
import SettingsDrawer from './modules/settingsDrawer';
import { useKmlManager } from './modules/settingsDrawer/modules/kmlLoader/useKmlManager';
import { useManageMapWithTemplate } from './behaviourHooks/useManageMapWithTemplate';
import { useAssetLabel } from '../shared/assetLabel';
import useAllFeatureAssets from "contexts/featureAssets/useAllFeatureAssets";

const trackingPanelStyle = {
  position: 'absolute',
  left: '50%',
  bottom: '60px',
  transform: 'translateX(-50%)',
  display: 'inline-block',
  mt: '10px',
  p: 2,
  borderRadius: 2,
  overflow: 'hidden',
  opacity: 1,
  textAlign: 'center',
  zIndex: 999,
  boxShadow: 'none',
  '& p': {
    fontWeight: 500,
    fontSize: '1rem',
    color: 'common.whiteConstant'
  },
};

const logError = error => {
  console.error(error);
  noticeError(error);
};

// NOTE: looks like a component but it isn't one so hooks can't be used
const fallbackRender = ({ resetErrorBoundary }) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 1,
    alignItems: 'center',
    gridRowStart: 1,
    gridColumnStart: 1,
    gridRow: 'span 1',
    gridColumn: 'span 1',
  }}>
    <Typography><Translation render={t => t('pages.map.error.message')} /></Typography>
    <Button variant="contained" size="large" onClick={resetErrorBoundary}><Translation render={t => t('pages.map.error.reset')} /></Button>
  </Box>
);

const Map = ({
  index,
  xs,
  config,
  template,
  selected,
  onMouseDown,
  selectedItemForThisMap,
  kmlFilenames,
  refetchKmls
}) => {
  const t = useTranslations('pages.map.mapControls');

  const featureAssets = useAllFeatureAssets();
  const assetLabel = useAssetLabel();
  const MapInstance = Maps[config.baseLayerLibrary];
  const isClosable = config.id !== 'default';

  const highContrastControls = useSelector(state => state.settings.map.highContrastControls);
  const follow = useSelector(state => state.map.assetsAreBeingFollowedOnMaps[config.id]);
  const selectedReport = useSelector(state => state.reports.selectedReportPerMap[config.id]);
  const measurementState = useSelector(selectMeasurementState);
  const dispatch = useAppDispatch();

  const [measureToggle, setMeasureToggle] = useState(false);
  const [distanceRingsToggle, setDistanceRingsToggle] = useState(false);
  const [velocityLeadersToggle, setVelocityLeadersToggle] = useState(false);
  const [reportToggle, setReportToggle] = useState(false);
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false);
  const [visibleKmls, kmlOrder, kmlStatus, setKmlOrder, setKmlStatus] = useKmlManager(config.id, kmlFilenames);

  // feature flag
  const multipointMeasurementFeature = useFeatureFlag('multipointMeasurementTool');

  const measurementModeEnabled = useMemo(() => {
    if (multipointMeasurementFeature) {
      return measurementState !== 'disabled';
    }
    return measureToggle;
  }, [measureToggle, measurementState, multipointMeasurementFeature]);

  // Measurement panel toggle on and off
  const toggleMeasurePanel = useCallback(() => {
    if (multipointMeasurementFeature) {
      dispatch(toggleMeasurementState());
    } else {
      setMeasureToggle(!measureToggle);
    }
    setReportToggle(false);
  }, [dispatch, measureToggle, multipointMeasurementFeature]);

  const disableMeasurementMode = useCallback(() => {
    if (multipointMeasurementFeature) {
      dispatch(setMeasurementState('disabled'));
    } else {
      setMeasureToggle(false);
    }
  }, [dispatch, multipointMeasurementFeature]);

  const setKmlOrderAndRefetch = useCallback(o => {
    setKmlOrder(o);
    refetchKmls();
  }, [setKmlOrder, refetchKmls]);

  const followAsset = useCallback(() => {
    dispatch(setFollow(config.id, !follow));
  }, [config.id, dispatch, follow]);

  useEffect(() => {
    if (selected) dispatch(setSelectedReport(config.id, null));
  }, [config.id, selected, selectedItemForThisMap, dispatch]);

  useEffect(() => {
    if (template.feature && template.replacementId && !featureAssets.getFeature(template.feature).some) {
      dispatch(setBaseLayer(config.id, { library: template.library, id: template.replacementId }));
    }
  }, [config.id, dispatch, featureAssets, template]);

  const [minZoom, maxZoom] = useManageMapWithTemplate(config.id, template);

  // Settings Drawer show and close logic
  const handleSettingsDrawerOpen = () => setSettingsDrawerOpen(true);
  const handleSettingsDrawerClose = () => setSettingsDrawerOpen(false);

  // Distance rings toggle on and off
  const toggleDistanceRings = () => {
    setDistanceRingsToggle(v => !v);
  };

  // Velocity leaders toggle on and off
  const toggleVelocityLeaders = () => {
    setVelocityLeadersToggle(v => !v);
  };

  const closeTheMap = () => {
    dispatch(closeMap(config.id));
  };

  return (
    <Grid item xs={xs} key={`grid${index}`} sx={{ position: 'relative', display: 'grid' }}>
      <ErrorBoundary
        fallbackRender={fallbackRender}
        onError={logError}
        onReset={() => {
          disableMeasurementMode();
          setDistanceRingsToggle(false);
          setVelocityLeadersToggle(false);
          setReportToggle(false);
        }}
      >
        <MapProvider>
          <MapInstance
            sx={{
              gridRowStart: 1,
              gridColumnStart: 1,
              gridRow: 'span 1',
              gridColumn: 'span 1'
            }}
            key={config.id}
            template={template}
            config={config}
            onMouseDown={onMouseDown}
            selected={selected}
            minZoom={minZoom}
            maxZoom={maxZoom}
            follow={follow}
            measureToggle={measurementModeEnabled}
            distanceRingsToggle={distanceRingsToggle}
            velocityLeadersToggle={velocityLeadersToggle}
            selectedReport={selectedReport}
            kmlFilenames={visibleKmls}
          >
            { follow && selectedItemForThisMap && (
            <Box
              aria-label="trackingPanel"
              sx={{
                ...(trackingPanelStyle),
                backgroundColor: highContrastControls ? 'mapControls.bgHighContrast' : 'rgba(0,0,0,.4)',
              }}>
              <Typography>{`${t('following')}: ${assetLabel(selectedItemForThisMap)}`}</Typography>
            </Box>
            ) }
          </MapInstance>
        </MapProvider>
      </ErrorBoundary>

      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          left: 20,
          ...(selected && {
            display: 'block',
          })
        }}
      >
        <MapControls
          provider={template.provider ?? 'Mapbox'}
          handleSettingsDrawerOpen={handleSettingsDrawerOpen}
          settingsDrawerOpen={settingsDrawerOpen}
          followAsset={followAsset}
          follow={follow}
          reportToggle={reportToggle}
          toggleMeasurePanel={toggleMeasurePanel}
          toggleDistanceRings={toggleDistanceRings}
          toggleVelocityLeaders={toggleVelocityLeaders}
          measureToggle={measurementModeEnabled}
          distanceRingsToggle={distanceRingsToggle}
          velocityLeadersToggle={velocityLeadersToggle}
          closeMap={closeTheMap}
          isClosable={isClosable}
          highContrastControls={highContrastControls}
          controlsVisible={selected}
          mapId={config.id}
          minZoom={minZoom}
          maxZoom={maxZoom}
        />
      </Box>

      <SettingsDrawer
        settingsDrawerOpen={settingsDrawerOpen}
        handleSettingsDrawerClose={handleSettingsDrawerClose}
        config={config}
        kmlOrder={kmlOrder}
        kmlStatus={kmlStatus}
        setKmlOrder={setKmlOrderAndRefetch}
        setKmlStatus={setKmlStatus}
      />
    </Grid>
  );
};

export default Map;
