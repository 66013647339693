import React from 'react';
import { useTranslations } from 'use-intl';
import { Flight, LocalFireDepartment, Timeline, AirlineStops, Insights, Analytics } from '@mui/icons-material';
import Page from 'components/pages/page';
import {
  SettingsMenuContainer,
  SettingsMenuPanelGrid,
  SettingsMenuPanelItem,
  SettingsMenuPageWrapper,
} from 'components/shared/settingsMenu';
import LabsSettingsMenuContainer from 'components/shared/labs/SettingsMenuContainer';
import useFeature from 'hooks/features/useFeature';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useStaff } from 'hooks/session/useStaff';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';

const ReportingPage = () => {
  const t = useTranslations('pages.reporting');
  const firefightingFeatureAssets = useFeatureAssets('reporting.aerialFirefightingReport');
  const activitySummaryFeatureAssets = useFeatureAssets('reporting.activitySummaryReport');
  const featureModulesFlag = useFeatureFlag('featureModules');
  const activitySummaryFlag = useFeatureFlag('utilisationInsights');
  const geofenceFlag = useFeatureFlag('frontendgeofencereports');
  const geofenceToggle = useFeature('reporting.geofences');
  const geofenceFeatureAssets = useFeatureAssets('reporting.geofences');
  const isStaff = useStaff();

  const firefighting = isStaff || firefightingFeatureAssets.some;

  const activitySummary = isStaff || activitySummaryFeatureAssets.some;

  const tripAnalysisFeatureAssets = useFeatureAssets('reporting.newTripReports');
  const tripAnalysis = isStaff || tripAnalysisFeatureAssets.some;

  const dailySummaryFeatureAssets = useFeatureAssets('reporting.dailyFlightSummary ');
  const dailySummary = isStaff || dailySummaryFeatureAssets.some;

  const geofenceEventsEnabled = isStaff
    || (featureModulesFlag && geofenceFeatureAssets.some)
    || (featureModulesFlag === false && !!geofenceToggle);

  return (
    <Page title={t('title')}>
      <SettingsMenuPageWrapper>
        <SettingsMenuContainer title={t('sections.reports')}>
          <SettingsMenuPanelGrid>
            <SettingsMenuPanelItem
              to="trip"
              icon={<Flight sx={{ fontSize: '1.8rem', display: 'block' }} />}
              title={t('tripReports.title')}
              description={t('tripReports.description')}
            />
            <SettingsMenuPanelItem
              to="mission"
              icon={<Timeline sx={{ fontSize: '1.8rem', display: 'block' }} />}
              title={t('missionReports.title')}
              description={t('missionReports.description')}
            />
            {featureModulesFlag && (
              <SettingsMenuPanelItem
                to="firefighting"
                icon={<LocalFireDepartment sx={{ fontSize: '1.8rem', display: 'block' }} />}
                title={t('firefighting.title')}
                description={t('firefighting.description')}
                disabled={!firefighting}
              />
            )}
            {featureModulesFlag && (
              <SettingsMenuPanelItem
                to="trip-analysis"
                icon={<AirlineStops sx={{ fontSize: '1.8rem', display: 'block' }} />}
                title={t('tripAnalysis.title')}
                description={t('tripAnalysis.description')}
                disabled={!tripAnalysis}
              />
            )}
          </SettingsMenuPanelGrid>
        </SettingsMenuContainer>

        <LabsSettingsMenuContainer description={t('sections.previewReports')}>
          <SettingsMenuPanelGrid>
            {activitySummaryFlag && (activitySummary || featureModulesFlag) && (
              <SettingsMenuPanelItem
                to="summary"
                icon={<Insights sx={{ fontSize: '1.8rem', display: 'block' }} />}
                title={t('utilisation.title')}
                description={t('utilisation.description')}
                disabled={!activitySummary}
              />
            )}
            {featureModulesFlag === false && (
              <SettingsMenuPanelItem
                to="trip-analysis"
                icon={<AirlineStops sx={{ fontSize: '1.8rem', display: 'block' }} />}
                title={t('tripAnalysis.title')}
                description={t('tripAnalysis.description')}
                disabled={!tripAnalysis}
              />
            )}
            <SettingsMenuPanelItem
              to="daily-flight-summary"
              icon={<Flight sx={{ fontSize: '1.8rem', display: 'block' }} />}
              title={t('dailyFlightSummary.title')}
              description={t('dailyFlightSummary.description')}
              disabled={!dailySummary}
            />
            {featureModulesFlag === false && (
              <SettingsMenuPanelItem
                to="firefighting"
                icon={<LocalFireDepartment sx={{ fontSize: '1.8rem', display: 'block' }} />}
                title={t('firefighting.title')}
                description={t('firefighting.description')}
                disabled={!firefighting}
              />
            )}
            {geofenceFlag && (
              <SettingsMenuPanelItem
                to="geofence-events"
                icon={<Analytics sx={{ fontSize: '1.8rem', display: 'block' }} />}
                title={t('geofenceEvents.title')}
                description={t('geofenceEvents.description')}
                disabled={!geofenceEventsEnabled}
              />
            )}
          </SettingsMenuPanelGrid>
        </LabsSettingsMenuContainer>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default ReportingPage;
